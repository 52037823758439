<div *ngIf="!isLoadingUser" class="h-full">
  <div class="mx-6 flex justify-center lg:top-0 lg:h-full lg:mx-0">
    <div class="w-full max-w-[485px] relative">
      <div class="h-full lg:px-8 2xl:px-12">
        <div class="relative lg:absolute cursor-pointer lg:top-6 mb-10 lg:mb-0 pt-6 lg:pt-0">
          <div (click)="backToHome()" class="flex items-center gap-x-2">
            <svg class="" width="12" height="12" viewBox="0 0 12 12" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.219749 5.47031L3.21975 2.47031C3.51272 2.17734 3.98733 2.17734 4.28029 2.47031C4.57326 2.76328 4.57326 3.23789 4.28029 3.53085L2.56174 5.25H11.25C11.6646 5.25 12 5.58539 12 6C12 6.41461 11.6646 6.75 11.25 6.75H2.56174L4.28158 8.46984C4.57455 8.76281 4.57455 9.23742 4.28158 9.53039C3.98885 9.82312 3.51424 9.82359 3.22104 9.53039L0.221038 6.53039C-0.0732199 6.23672 -0.0732199 5.76328 0.219749 5.47031Z"
                fill="#1d1d1ba3" />
            </svg>
            <span class="text-xs text-[#1d1d1ba3]">
              {{ "search_page.back" | translate }}
            </span>
          </div>
        </div>
        <div class="flex flex-col items-center lg:justify-center mx-auto h-full">
          <div class="mb-10 lg:mb-12">
            <img alt="logo" [src]="ImageManager.getLogoNavbarByTerritoryCode()" width="198" height="47">
          </div>
          <div class="w-full">
            <h1 class="text-center text-2xl lg:text-3xl font-semibold">
              {{ "login.login_text" | translate }}
            </h1>
            <p class="text-base text-center color-link mt-[0.5rem] md:mt-[1rem]">
              {{ 'login.dont_have_account' | translate }}
              <a (click)="goToRegister()"
                class="font-medium text-primary-600 hover:underline cursor-pointer color-link">{{ 'register.title' |
                translate }}</a>
            </p>
            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="">
              <div class="grid gap-6 mt-10 lg:mt-12">
                <div class="form-group flex items-center">
                  <!-- <label class="block mb-2 text-sm font-medium text-gray-900"
                         for="email">{{"login.your_email" | translate}}</label> -->
                  <div class="absolute flex item-center px-3">
                    <svg height="17" viewBox="0 0 448 512" width="14" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
                        opacity="0.5" />
                    </svg>
                  </div>
                  <input
                    class="custom-input bg-white border-solid-black text-base rounded-lg block w-full px-10 py-[12px] md:py-3.5"
                    formControlName="email" id="email" name="email"
                    placeholder="{{'login.insert_your_email' | translate}}" required="" type="text">
                </div>
                <div class="form-group flex items-center">
                  <!-- <label class="block mb-2 text-sm font-medium text-gray-900" for="password">Password</label> -->
                  <div class="absolute flex item-center px-3">
                    <svg fill="none" height="17" viewBox="0 0 14 17" width="14" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 6.5V4.5C3 2.3125 4.78125 0.5 7 0.5C9.1875 0.5 11 2.3125 11 4.5V6.5H11.5C12.875 6.5 14 7.625 14 9V14C14 15.4062 12.875 16.5 11.5 16.5H2.5C1.09375 16.5 0 15.4062 0 14V9C0 7.625 1.09375 6.5 2.5 6.5H3ZM4 6.5H10V4.5C10 2.84375 8.65625 1.5 7 1.5C5.3125 1.5 4 2.84375 4 4.5V6.5ZM1 14C1 14.8438 1.65625 15.5 2.5 15.5H11.5C12.3125 15.5 13 14.8438 13 14V9C13 8.1875 12.3125 7.5 11.5 7.5H2.5C1.65625 7.5 1 8.1875 1 9V14Z"
                        fill="black" opacity="0.5" />
                    </svg>
                  </div>
                  <input
                    class="custom-input bg-white border-solid-black text-base rounded-lg block w-full py-[12px] md:py-3.5 px-10"
                    formControlName="password" id="password" name="password" placeholder="Password" required
                    type="password">
                </div>
              </div>
              <button *ngIf="!isLoading" [disabled]="!loginForm.valid"
                class="custom-border-solid w-full btn-default rounded-lg text-base font-medium px-3.5 text-center mt-6 py-[12px] md:py-3.5"
                type="submit">
                {{'login.login_with_mail' | translate}}
              </button>
              <div *ngIf="isLoading" class="flex justify-center items-center mt-2">
                <div class="spinner-border custom-spinner" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-10 lg:mt-12 grid grid-cols-3 items-center">
                <hr class="border-[#1d1d1ba3]" />
                <p class="text-center text-sm text-[#1d1d1ba3]">{{'login.or' | translate}}</p>
                <hr class="border-[#1d1d1ba3]" />
              </div>
              <div class="w-full flex mt-10 lg:mt-12">
                <button (click)="keycloakLogin()" [disabled]="isFromThirdParty"
                  [ngClass]="{'opacity-50 grayscale': isFromThirdParty}"
                  class="w-full bg-white border-solid-black rounded-lg flex justify-center items-center text-base py-[12px] md:py-3.5"
                  type="button">
                  <svg class="mr-2" fill="none" height="24" viewBox="0 0 24 24" width="24"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_308_846)">
                      <path
                        d="M23.7663 12.2764C23.7663 11.4607 23.7001 10.6406 23.559 9.83807H12.2402V14.4591H18.722C18.453 15.9494 17.5888 17.2678 16.3233 18.1056V21.1039H20.1903C22.4611 19.0139 23.7663 15.9274 23.7663 12.2764Z"
                        fill="#4285F4" />
                      <path
                        d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
                        fill="#34A853" />
                      <path
                        d="M5.50277 14.3003C5.00011 12.8099 5.00011 11.1961 5.50277 9.70575V6.61481H1.51674C-0.185266 10.0056 -0.185266 14.0004 1.51674 17.3912L5.50277 14.3003Z"
                        fill="#FBBC04" />
                      <path
                        d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
                        fill="#EA4335" />
                    </g>
                    <defs>
                      <clipPath id="clip0_308_846">
                        <rect fill="white" height="24" width="24" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="font-medium">{{ 'login.google_login' | translate }}</span>
                </button>
                <!--                notify why it is disabled -->

              </div>
              <div *ngIf="isFromThirdParty" class="text-center text-xs text-red-500 w-100 mt-2">
                {{'login.third_party_disabled' | translate}}
              </div>
              <ngb-alert class="mt-3 w-100" #staticAlert (closed)="isShowing = false" *ngIf="isShowing"
                [dismissible]="false" type="danger">
                <div class="font-bold text-center w-100">
                  {{ errorTextCode | translate}}
                </div>
              </ngb-alert>
              <div class="flex justify-center mt-10 lg:mt-12">
                <p class="text-center text-xs text-[#1d1d1ba3]">
                  {{'login.accept_tos' | translate}}
                  <a class="underline text-[#1d1d1ba3]" href="{{getTermOfServiceUrl()}}" target="_blank">{{'login.tos'
                    | translate}}</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div class="lg:w-full">
      <!-- <img src="/assets/login-background.png" alt="Background login"> -->
      <div class="bg-cover bg-center h-full w-full" style="background-image: url(/assets/login-background.jpg);"></div>
    </div>
  </div>
</div>