<div class="grid grid-cols-2 gap-[0.75rem] lg:gap-6">
  <div *ngFor="let territory of availableTerritories" [ngClass]="{
        'bg-chianti': territory.code === 'chianti',
        'bg-mugello': territory.code === 'mugello',
        'border-[1px] md:border-2 custom-border-color custom-shadow': territory.code === currentTerritoryCode
      }"
    class="group w-full rounded-xl lg:rounded-3xl relative bg-center bg-no-repeat bg-cover h-40 sm:h-44 md:h-52 lg:h-72 xl:h-[22rem] 2xl:h-[430px] cursor-pointer transition-all"
    (click)="goToXplorerTerritory(territory)">
    <div *ngIf="territory.code === currentTerritoryCode"
      class="absolute top-3 md:top-10 right-3 md:right-10 z-50 text-white">
      <div class="flex flex-col gap-y-1 md:gap-y-2 items-center">
        <img [src]="ImageManager.getWhiteLogoIconTerritory()" [alt]="territory.name" class="w-[14px] md:w-[31px] h-[18px] md:h-10">
        <p class="text-[8px] lg:text-xs text-medium text-white">Sei qui!</p>
      </div>
    </div>
    <div class="w-full h-full flex justify-center items-center">
      <img [src]="ImageManager.getWhiteLogoHomeByTerritoryCode(territory.code)" [alt]="territory.name"
        class="w-[132px] h-[29px] lg:w-[331px] lg:h-[79px]">
    </div>
  </div>
</div>
