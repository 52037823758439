<a href="explore/{{this.xploreItem.id}}">
  <div
    class="rounded-xl lg:rounded-3xl flex items-end aspect-[9/16] w-full max-w-[162px] sm:max-w-[241px] md:max-w-[177px] lg:max-w-full relative overflow-hidden">

    <img [src]="getImage()" class="absolute inset-0 h-full w-full object-cover" alt="Xplore Placeholder">
    <div
      class="py-[1rem] px-[0.5rem] md:py-6 md:px-4 xl:py-12 xl:px-6 bg-gradient-to-b from-transparent to-black rounded-b-xl lg:rounded-b-3xl relative z-10 w-full">
      <div class="mb-[0.5rem] lg:mb-[1rem] w-full">
        <h2 class="text-white font-semibold text-sm sm:text-base lg:text-xl 2xl:text-2xl line-clamp-2">
          {{xploreItem.name}}</h2>
      </div>

      <div *ngIf="xploreItem.geoPositionDTO?.comune">
        <p class="text-white text-xs sm:text-sm lg:text-base line-clamp-1">{{xploreItem.geoPositionDTO.comune.name}}</p>
      </div>

    </div>
  </div>
</a>

<!--<a href="explore/{{this.xploreItem.id}}">-->
<!--  <div class="max-w-sm rounded-lg overflow-hidden shadow-sm">-->
<!--    <div class="w-full h-60vh xl:h-80vh overflow-hidden">-->
<!--      <img [src]="getImage()"-->
<!--           class="object-scale-down object-center"-->
<!--           alt="Xplore Placeholder">-->
<!--    </div>-->
<!--    <div class="p-1 h-auto">-->
<!--      <p class="font-bold text-xs">{{xploreItem.name}}</p>-->
<!--      <div class="flex flex-row" *ngIf="xploreItem.geoPositionDTO?.comune">-->
<!--        <svg class="w-4 h-4" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="3 1 25 25"-->
<!--             xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" stroke-linecap="round"-->
<!--                stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--        <p class="text-xs my-auto truncate">{{xploreItem.geoPositionDTO.comune.name}}</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</a>-->