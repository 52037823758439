<!-- <div class="flex items-center">
  <label class="sr-only" for="simple-search">Search</label>
  <div class="relative w-full">
    <input #inputName (focus)="emitEvent(true)" (focusout)="emitEvent(false)"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-red-800 block w-full p-2.5"
      id="simple-search" placeholder="Search..." required type="text">
  </div>
  <button (click)="buttonClicked()"
    class="custom-color p-2.5 ml-2 text-sm font-medium text-white rounded-lg border  focus:outline-none">
    <svg aria-hidden="true" class="w-4 h-4" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" stroke="currentColor" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="2" />
    </svg>
    <span class="sr-only">Search</span>
  </button>
</div> -->

<div class="w-full">
  <div class="bg-[#DCE4E7] flex rounded-full py-2 pl-6 pr-2 h-14 lg:h-12 w-full">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.904 192.904" width="16px"
      class="fill-[#1D1D1B] mr-4 opacity-75">
      <path
        d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z">
      </path>
    </svg> -->

    <!--Aggiunto keyup.enter per permettere la ricerca cliccando il pulsante invio-->
    <input #inputName (focus)="emitEvent(true)" (focusout)="emitEvent(false)" type="text" (keyup.enter)="buttonClicked()"
      [placeholder]="'home.search_placeholder' | translate"
           [(ngModel)]="inputText"
      class="w-full outline-none bg-transparent placeholder-[#1D1D1B] placeholder-opacity-75 text-base lg:text-sm"/>

    <button class="rounded-full custom-background-color px-[13px] lg:px-[9px] h-full flex items-center justify-center" (click)="buttonClicked()">
      <svg class="w-[14px] h-[14px]" fill="none" stroke="#fff" stroke-width="2" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="ImageManager.searchIcon" />
      </svg>
    </button>

  </div>
</div>
