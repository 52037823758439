import {environment} from "../../environments/environment";

export class ImageManager {
  public static instagramLogo = "/assets/icon/instagram_logo.svg";

  public static italianFlagIcon = "/assets/icon/it_flag.svg"
  public static englishFlagIcon = "/assets/icon/en_flag.svg"

  public static bookmarkBookIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#bookmark-book";
  public static mapIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#map";
  public static searchIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#magnifier";
  public static userIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#user-icon";
  public static exploreIcon = "/assets/xplorer/shared/icons/tabbar-group.svg#explore-icon";

  public static bookmarkIcon = "/assets/xplorer/shared/icons/bookmark.svg#default";

  public static immersiveIcon = "/assets/xplorer/shared/icons/3d-icon.svg#default";
  public static shareIcon = "/assets/xplorer/shared/icons/send.svg#default";
  public static cameraIcon = "/assets/xplorer/shared/icons/camera.svg#default";

  public static blackHoleIcon = "/assets/xplorer/shared/icons/black-hole.svg#default";

  public static checkIcon = "/assets/xplorer/shared/icons/check.svg#default";
  public static checkCircleIcon = "/assets/xplorer/shared/icons/check-circle.svg#default";
  public static arrowDown = "/assets/xplorer/shared/icons/alt-arrow-down.svg#default";

  public static profileIcon = "/assets/profile_placeholder.svg#default";

  public static searchFailed = "/assets/xplorer/shared/illustrations/result-not-found.svg#default";

  static getLogoIconSvg(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo.svg";
  }

  static getWhiteLogoSvg(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_white.svg";
  }

  static getLogoIconSvgByTerritoryCode(territoryCode: string): string {
    return "/assets/xplorer/" + territoryCode + "/logo/logo.svg";
  }

  static getLogoXplorerFull(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_full_platform.png";
  }

  static getLogoXplorerFullByTerritoryCode(territoryCode: string): string {
    return "/assets/xplorer/" + territoryCode + "/logo/logo_full_platform.png";
  }

  static getPlaceholderImage(): string {
    return "/assets/img_placeholder_9_16.jpg";
  }

  static getLogoHeaderMobileByTerritoryCode(): string {
   return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_navbar_mobile.svg";
  }

  public static getLogoModalByTerritoryCode(territoryCode: string): string {
    return `/assets/xplorer/${territoryCode}/logo/logo_navbar_mobile.svg`;
  }

  static getProfilePlaceholder(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/profile_placeholder.svg";
  }

  static getLogoNavbar(): string {
    return "/assets/logo_navbar.svg";
  }

  static getLogoNavbarByTerritoryCode(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_navbar.svg";
  }

  static getLogoBottomBarByTerritoryCode(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_bottom_bar.svg";
  }

  static getWhiteLogoHomeByTerritoryCode(territoryCode: string): string {
    return "/assets/xplorer/" + territoryCode + "/logo/logo_white_home.svg";
  }

  static getWhiteLogoIconTerritory(): string {
    return "/assets/xplorer/" + getTerritoryCodeFromUrl() + "/logo/logo_icon_territory.svg";
  }

  static getCategoryImageByTerritoryCode(categoryName: string): string {
    return `/assets/xplorer/` + getTerritoryCodeFromUrl() + `/filter-assets/${categoryName}`;
  }
  
}

function getTerritoryCodeFromUrl(): string {
  let windowUrl =  window.location.href;
  if (environment.IS_LOCAL) {
    windowUrl = "https://mugello.xplorer-app.com/explore";
  }
  let subdomain = windowUrl.split(".")[0];
  return subdomain.split("//")[1];
}

