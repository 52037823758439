<div class="overflow-y-auto height-without-bar lg:h-full">
  <div *ngIf="isLoading" class="w-full h-full flex justify-center items-center">
    <div class="spinner-border custom-big-spinner border-white" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="mt-6 lg:mt-40 pb-12 lg:pb-20">
    <div class="container px-[0.75rem] mx-auto">
      <!-- Risultati caricati -->
      <div *ngIf="isLoading; else results"
        class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[12px] gap-y-6 lg:gap-x-6 lg:gap-y-10">
        <ng-container *ngFor="let skeleton of skeletons">
          <app-placeholder-card></app-placeholder-card>
        </ng-container>
      </div>

      <ng-template #results>
        <div *ngIf="favorites?.length > 0"
          class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[12px] gap-y-6 lg:gap-x-6 lg:gap-y-10"
          infiniteScroll>
          <app-preview-item-card *ngFor="let item of favorites" [xploreItem]="item" class=""></app-preview-item-card>
        </div>

        <div *ngIf="!favorites || favorites?.length == 0"
          class="flex flex-col justify-center items-center h-[calc(100vh-350px)] text-center">
          <!-- Nessun preferito -->
          <h2 class="font-semibold text-2xl md:text-3xl my-6">{{ 'favorites_page.no_favorites' | translate }}</h2>
          <span class="text-sm md:text-base">
            {{ 'favorites_page.no_favorites_text' | translate }}
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
