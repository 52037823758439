import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CommercialDTO,
  MultiMediaDTO,
  TagDTO,
  UserAccountDTO,
  XploreItemDTO
} from 'src/app/shared/object/xplorer-be-parsed-classes';
import { environment } from "../../../../environments/environment";
import {ImageManager} from "../../../shared/image-manager";

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.css']
})
export class ItemCardComponent {

  @Input() xploreItem: XploreItemDTO;
  @Input() distanceFromUser: string;
  @Input() isFavorite: boolean;
  @Input() canShare: boolean;

  @Output() favoriteButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() shareButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() immersiveButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchTagClickedEvent: EventEmitter<TagDTO> = new EventEmitter<TagDTO>();
  @Output() userProfileClickedEvent: EventEmitter<UserAccountDTO> = new EventEmitter<UserAccountDTO>();

  protected readonly ImageManager = ImageManager;

  constructor() { }

  getImage(multimediaDTO: MultiMediaDTO) {
    return multimediaDTO?.mediaURL ? environment.IMAGES_URL + multimediaDTO.mediaURL : ImageManager.getPlaceholderImage();
  }

  favoriteButtonClicked() {
    this.favoriteButtonClickedEvent.emit();
  }

  share(): void {
    this.shareButtonClickedEvent.emit();
  }

  goToImmersive() {
    this.immersiveButtonClickedEvent.emit();
  }

  goToSearchTag(tag: TagDTO) {
    this.searchTagClickedEvent.emit(tag);
  }

  goToUserProfile(userAccount: UserAccountDTO) {
    this.userProfileClickedEvent.emit(userAccount);
  }

  get hasOwner() {
    return this.xploreItem.itemType === 'COMMERCIAL'
      && (this.xploreItem as CommercialDTO).company
  }

  get getOwnerName() {
    if (this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company.name
    }
    return ''
  }

  get isEvent() {
    return this.xploreItem.itemType === 'EVENT'
  }

  get firstLevelTags() {
    return this.xploreItem.tags.filter(tag => tag.tagLevel == 0)
  }
}
