import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'xplorer-bowser-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrl: './menu-dropdown.component.css',
})
export class MenuDropdownComponent implements OnInit {

  isUserLoggedIn: boolean = false;

  constructor(private router: Router, private modalService: ModalService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.isUserLogged().subscribe(isLogged => {
      this.isUserLoggedIn = isLogged;
    });
  }

  goToRegister(): void {
    this.router.navigate(['/signup']);
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  onLocationClick() {
    this.modalService.open();
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.isUserLoggedIn = false;
    }, error => {
      console.error('Logout failed:', error);
    });
  }

}
