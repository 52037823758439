import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private visibilitySource = new BehaviorSubject<boolean>(false);
  public isVisible$ = this.visibilitySource.asObservable();

  open() {
    this.visibilitySource.next(true);
  }

  close() {
    this.visibilitySource.next(false);
  }

  toggle() {
    this.visibilitySource.next(!this.visibilitySource.value);
  }
}
