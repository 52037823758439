import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {TerritoryService} from "./geolocation/territory.service";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private readonly http: HttpClient,
              private readonly territoryService: TerritoryService) { }

  getUpcomingPopularEvents() {
    let params = this.buildStandardParams();
    return this.http.get(`${environment.API_URL}/events/upcoming` , {params: params});
  }

  private buildStandardParams(noDraft: boolean = true): HttpParams {
    return new HttpParams()
      .set('territoryCode', this.territoryService.getTerritoryCodeFromUrl())
      .set('noDraft', noDraft);
  }
}
