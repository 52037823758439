<div class="h-16 fixed bottom-0 left-0 right-0 w-full bg-white shadow-[0_4px_16px_rgba(0,0,0,.1)] z-50 border-t border-[#DCE4E7]">
  <ul class="h-full flex text-center bg-white ">
    <li class="w-full">
      <a class="flex items-center justify-center h-full box-border opacity-80" routerLink="/search" routerLinkActive="custom-tab-active opacity-100">
        <div class="flex-col flex items-center text-[#1D1D1B]">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
               stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.searchIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="flex items-center justify-center h-full box-border opacity-80" routerLink="/map" routerLinkActive="custom-tab-active opacity-100">
        <div class="flex-col flex items-center text-[#1D1D1B]">
          <svg class="w-7 h-7" fill="none" stroke="currentColor" stroke-width="1.7"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\
            <use [attr.xlink:href]="ImageManager.mapIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="flex items-center justify-center h-full no-context-menu" routerLink="/" routerLinkActive="">
        <div class="flex-col flex items-center">
          <img alt="Explore icon" class="" routerLinkActive="tab-explore-active"
               [src]="ImageManager.getLogoBottomBarByTerritoryCode()">
        </div>
      </a>
    </li>

    <!-- <li class="w-full">
      <a class="flex items-center justify-center h-full box-border opacity-80" routerLink="/favorites" routerLinkActive="custom-tab-active opacity-100">
        <div class="flex-col flex items-center text-[#1D1D1B]">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.bookmarkBookIcon"/>
          </svg>
        </div>
      </a>
    </li> -->

    <li class="w-full">
      <a class="flex items-center justify-center h-full box-border opacity-80" routerLink="/explore" routerLinkActive="custom-tab-active opacity-100">
        <div class="flex-col flex items-center text-[#1D1D1B]">
          <svg class="w-[26px] h-[26px]" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.exploreIcon"/>
          </svg>
        </div>
      </a>
    </li>

    <li class="w-full">
      <a class="flex items-center justify-center h-full box-border opacity-80" routerLink="/profile" routerLinkActive="custom-tab-active opacity-100" longPress (longPressEmitter)="onLongPress()">
        <div class="flex-col flex items-center text-[#1D1D1B]">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="2"
               viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="ImageManager.userIcon"/>
          </svg>
<!--          <p class="text-xxsm font-regular">{{'tabs.profile' | translate}}</p>-->
        </div>
      </a>
    </li>
  </ul>
</div>
