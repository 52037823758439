<div
    class="w-full mx-auto lg:h-[424px] xl:h-[532px] 2xl:h-[40rem] rounded-[40px] relative overflow-hidden hidden lg:block">
    <div class="absolute inset-0 w-full h-full">
        <img src="/assets/hero_bg_1000.webp" srcset="/assets/hero_bg_1000.webp 1000w,
            /assets/hero_bg_1256.webp 1256w,
            /assets/hero_bg.webp 1536w" sizes="(max-width: 1279px) 1000px,
           (max-width: 1535px) 1256px,
           1536px" alt="Hero Background" class="w-full h-full object-cover object-center">
    </div>

    <div
        class="relative z-10 h-full flex flex-col justify-center items-start lg:py-10 lg:pl-16 2xl:py-20 2xl:pl-20 lg:gap-12">
        <h1
            class="text-white uppercase text-4xl lg:text-5xl 2xl:text-7xl font-bold lg:leading-tight 2xl:leading-tight max-w-sm	">
            {{ 'home.hero.title' | translate }}
        </h1>

        <a class="btn text-[#ffffff] hover:text-black hover:bg-white text-base rounded-xl w-48 py-[18px] px-8 border border-white font-semibold"
            routerLink="/explore">
            {{'home.hero.button_text' | translate }}
        </a>

        <div class="absolute flex items-center gap-3 z-10 bottom-10">
            <p class="text-white font-medium">{{ 'home.hero.scroll' | translate }}</p>
            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="animate-bounce">
                <g clip-path="url(#clip0_1330_1056)">
                    <path
                        d="M0.366409 10.3671C0.608597 10.121 0.928909 9.99988 1.24922 9.99988C1.56953 9.99988 1.88907 10.122 2.13282 10.3661L6.24922 14.4803L6.24922 2.49989C6.24922 1.80887 6.80899 1.28504 7.46406 1.28504C8.11914 1.28504 8.74922 1.80848 8.74922 2.49989L8.74922 14.4803L12.8652 10.3671C13.3535 9.87879 14.1445 9.87879 14.6328 10.3671C15.1211 10.8554 15.1211 11.6464 14.6328 12.1346L8.38281 18.3846C7.89453 18.8729 7.10352 18.8729 6.61524 18.3846L0.365237 12.1346C-0.121872 11.6444 -0.121872 10.8554 0.366409 10.3671Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1330_1056">
                        <rect width="15" height="20" fill="white" transform="matrix(-1 0 0 -1 15 20)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</div>

<div class="container mx-auto px-0 lg:hidden relative py-10">
    <div class="mb-[1.5rem]">
        <h1 class="text-4xl leading-tight font-semibold w-[265px]">{{ 'home.hero.discover_places' | translate }}</h1>
    </div>
    <search-input (searchButtonClicked)="searchClicked($event)"></search-input>
</div>
