import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSetting } from './app.setting';
import { HeaderComponent } from './views/components/header/header.component';
import { XplorePageComponent } from './views/pages/xplore-page/xplore-page.component';
import { SearchPageComponent } from './views/pages/search-page/search-page.component';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbActiveModal, NgbDropdown, NgbDropdownMenu, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { XploreItemInfoPageComponent } from './views/pages/xplore-item-info-page/xplore-item-info-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginPageComponent } from './views/pages/login-page/login-page.component';
import { SignupPageComponent } from './views/pages/signup-page/signup-page.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { XploreItemPreviewComponent } from './views/debug/xplore-item-preview/xplore-item-preview.component';
import { AuthInterceptor } from "./services/interceptor/auth.interceptor";
import { errorInterceptorProviders } from "./services/interceptor/errors.interceptor";
import { FavoritesPageComponent } from './views/pages/favorites-page/favorites-page.component';
import { ComponentsModule } from "./views/components/components.module";
import { PageTabbarComponent } from "./views/components/page-tabbar/page-tabbar.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./services/auth/keycloak-init.factory";
import { LogoutPageComponent } from "./views/pages/logout-page/logout-page.component";
import {InViewportModule} from "ng-in-viewport";
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';
import { HomeComponent } from './views/pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HotToastModule} from "@ngneat/hot-toast";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    XplorePageComponent,
    SearchPageComponent,
    XploreItemInfoPageComponent,
    LoginPageComponent,
    SignupPageComponent,
    XploreItemPreviewComponent,
    FavoritesPageComponent,
    PageTabbarComponent,
    LogoutPageComponent,
    HomeComponent
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    BrowserModule,
    InViewportModule,
    AppRoutingModule,
    InfiniteScrollDirective,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.webApp,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    HotToastModule.forRoot()
  ],
  providers: [
    AppSetting,
    NgbActiveModal,
    NgbDropdown,
    NgbDropdownMenu,
    errorInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
