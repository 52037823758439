import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { ImageManager } from 'src/app/shared/image-manager';
import { Filter } from 'src/app/shared/object/filter';

@Component({
  selector: 'xplorer-bowser-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  categories: Filter[] = [];
  displayedCategories: Filter[] = [];
  screenWidth: number;

  protected readonly ImageManager = ImageManager;

  constructor(private router: Router, private filterService: FilterService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.loadCategories();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    this.updateDisplayedCategories();
  }

  loadCategories(): void {
    this.filterService.initFilters();
    this.categories = this.filterService.getFilters();

    this.updateDisplayedCategories();
  }

  updateDisplayedCategories(): void {
    this.displayedCategories = this.categories.slice(0, 6);
  }

  navigateTo(category: Filter): void {
    this.router.navigate(['search'], { queryParams: { tag: category.API_name } });
  }

}
