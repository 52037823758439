import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { XploreItemDTO } from 'src/app/shared/object/xplorer-be-parsed-classes';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { XploreItemService } from 'src/app/services/xplore-item.service';
import { FilterService } from 'src/app/services/filter.service';
import { Filter } from 'src/app/shared/object/filter';
import { MetaDataService } from "../../../services/meta/meta-data.service";
import { SearchInputComponent } from "./search-input/search-input.component";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ImageManager } from 'src/app/shared/image-manager';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css'],
})
export class SearchPageComponent implements OnInit {

  @ViewChild(SearchInputComponent) searchInput: SearchInputComponent;

  // TODO (Check if needed) Aggiungere l input per ricevere un tag da cercare

  filters: Filter[] = [];
  showingItems: XploreItemDTO[] = [];
  skeletons: number[] = [];
  loading = false;
  errorHappened: boolean = false;
  loadedIndex = 0;
  isScrollActive: Boolean = true;
  isUsingSearchBar = false;
  desktop: boolean;
  searchTerm: string | null = null;
  hasResults: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  private mainServiceSubscription$: Subscription;

  protected readonly ImageManager = ImageManager;

  constructor(
    private filterService: FilterService,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.filters = filterService.getFilters();
    this.desktop = window.innerWidth > 640
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.desktop = window.innerWidth > 640
  }

  ngOnInit(): void {
    this.metaDataService.setDefaultMetaData();

    this.loading = true;

    this.route.queryParams.subscribe(params => {

      console.debug(params)

      const tagToSearch = params['tag'];
      this.searchTerm = params['name'] || null;

      if (tagToSearch) {
        this.filterService.activateFilter(tagToSearch);
        this.getXploreItems(this.searchTerm, [tagToSearch], this.loadedIndex);
      } else {
        this.getXploreItems(this.searchTerm, this.filterService.getActiveFilters(), this.loadedIndex);
      }

    });
  }

  getXploreItems(name: string, tags: string[], index: number) {
    this.loading = true;
    this.skeletons = Array(12).fill(0);
    this.showingItems = [];

    this.xploreItemService.getXploreItemsFilteredByTagAndName(name, tags, index)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: xploreItems => {
          this.loading = false;

          // Gestione dei risultati
          this.showingItems = xploreItems || []; // Imposta un array vuoto se xploreItems è null o undefined
          this.hasResults = this.showingItems.length > 0; // Imposta hasResults in base alla lunghezza di showingItems

          // Aggiorna gli skeletons per riflettere il numero di risultati
          this.skeletons = this.hasResults ? Array(this.showingItems.length).fill(0) : [];
        },
        error: _ => {
          this.loading = false;
          this.errorHappened = true;
          this.showingItems = [];
          this.skeletons = [];
          this.hasResults = false;
        }
      })
  }

  searchItems = (): void => {
    this.searchTerm = this.searchInput.getName();
    let activeFilters = this.filterService.getActiveFilters();
    this.location.go(`/search?name=${this.searchTerm}`);

    this.getXploreItems(this.searchTerm, activeFilters, this.loadedIndex)
  }

  onScroll() {
    this.mainServiceSubscription$ = this.xploreItemService.getPagedXploreItems(this.loadedIndex)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: xploreItems => {
          if (xploreItems) {
            this.showingItems = [...this.showingItems, ...xploreItems];
            this.loadedIndex++;
          }
        },
        error: _ => {
          this.errorHappened = true
          this.loading = false
        }
      })
  }

}
