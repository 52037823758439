<div *ngIf="errorHappened; else content" class="overflow-y-auto height-without-bar lg:h-full">
  <div class="h-100 w-100 flex align-items-center">
    <div class="flex flex-column justify-content-center h-fit w-100">
      <img class='mx-auto h-36 w-36' src="../../../../assets/dizzy-robot.png" alt="image">
      <p class="text-lg mx-auto">{{ 'alter.msg' | translate }}</p>
    </div>
  </div>
</div>

<ng-template #content>
  <div (scroll)="isScrollActive" class="overflow-y-auto height-without-bar lg:h-full">
    <div class="pt-6 sticky top-0 bg-white z-50 shadow-[0_4px_16px_rgba(0,0,0,.1)] lg:hidden">
      <div class="pb-[1rem]">
        <div class="container px-[0.75rem] mx-auto mb-[20px]">
          <!-- <h1 *ngIf="!isUsingSearchBar || desktop" class="text-3xl font-bold text-gray-900 ">{{'tabs.search' |
            translate}}</h1> -->
          <search-input [inputText]="searchTerm" (isSearchingEmitter)="this.isUsingSearchBar = $event"
            (searchButtonClicked)="searchItems()" class=""></search-input>
        </div>
        <div
          class="container px-[0.75rem] mx-auto w-full no-scrollbar overflow-x-auto flex gap-[1rem] md:justify-center">
          <app-filter-button *ngFor="let filter of filters" [filter]="filter" [loadedIndex]="loadedIndex"
            [updateItemsFunction]="searchItems" class="flex-none"></app-filter-button>
        </div>
      </div>
    </div>
    <div class="mt-6 lg:mt-40 pb-12 lg:pb-20">
      <div class="container px-[0.75rem] mx-auto">
        <div class="lg:mb-12 lg:mx-auto hidden lg:flex lg:gap-x-10 lg:justify-center">
          <app-filter-button *ngFor="let filter of filters" [filter]="filter" [loadedIndex]="loadedIndex"
            [updateItemsFunction]="searchItems" class="flex-none"></app-filter-button>
        </div>

        <!-- Messaggio per i risultati trovati -->
        <p *ngIf="!loading && searchTerm && showingItems.length > 0" class="text-sm mb-6">
          {{ 'search.results_for' | translate }} "<span class="font-medium">{{ searchTerm }}</span>"
        </p>

        <!-- Messaggio 'No result' -->
        <div *ngIf="!loading && searchTerm && showingItems.length === 0"
          class="flex flex-col justify-center items-center h-[calc(100vh-350px)] text-center">
          <svg class="w-64 md:w-80 h-64 md:h-80 mx-auto" viewBox="0 0 500 500">
            <use [attr.xlink:href]="ImageManager.searchFailed" />
          </svg>

          <h2 class="font-semibold text-2xl md:text-3xl my-6">
            {{ 'search.no_results' | translate }}
          </h2>
          <p class="text-sm md:text-base">
            {{ 'search.no_match' | translate }} "<span class="font-medium">{{ searchTerm }}</span>"
          </p>
          <p class="text-sm md:text-base">
            {{ 'search.try_different_term' | translate }}
          </p>
        </div>

        <!-- Risultati caricati -->
        <div *ngIf="loading; else results"
          class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[12px] gap-y-6 lg:gap-x-6 lg:gap-y-10">
          <ng-container *ngFor="let skeleton of skeletons">
            <app-placeholder-card></app-placeholder-card>
          </ng-container>
        </div>

        <ng-template #results>
          <div (scrolled)="onScroll()" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[12px] gap-y-6 lg:gap-x-6 lg:gap-y-10" id="box"
            infiniteScroll>
            <app-preview-item-card *ngFor="let item of showingItems" [xploreItem]="item" class="">
            </app-preview-item-card>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
