import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../../services/auth/auth.service";
import { ImageManager } from 'src/app/shared/image-manager';
import { TerritoryService } from 'src/app/services/geolocation/territory.service';
import { environment } from 'src/environments/environment';
import { LocationSwitchPopupComponent } from '../location-switch-popup/location-switch-popup.component';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'xplorer-bowser-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css']
})
export class MobileHeaderComponent implements OnInit {
  public ImageManager = ImageManager;
  
  currentTerritoryCode: string;
  currentTerritoryName: string;

  @ViewChild('switchPopupComponent') locationSwitchPopupComponent: LocationSwitchPopupComponent;


  constructor(private authService: AuthService, private territoryService: TerritoryService, private modalService: ModalService) { }

  ngOnInit(): void {
    this.setCurrentTerritory();
  }

  setCurrentTerritory(): void {
    this.currentTerritoryCode = this.territoryService.getTerritoryCodeFromUrl();
    const territory = environment.AVAILABLE_TERRITORY.find(t => t.code === this.currentTerritoryCode);
    this.currentTerritoryName = territory ? territory.name : 'Territory Not Found';
  }

  isUserFullyLogged() {
    return this.authService.isUserLogged();
  }

  onLocationClick() {
    this.modalService.open();
  }

}
