import {
  AfterViewInit, ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import {XploreItemService} from '../../../services/xplore-item.service';
import {TagDTO, UserAccountDTO, XploreItemDTO} from '../../../shared/object/xplorer-be-parsed-classes';
import {catchError, EMPTY, map, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";
import {MetaDataService} from "../../../services/meta/meta-data.service";
import { Location } from '@angular/common';
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {TerritoryService} from "../../../services/geolocation/territory.service";
import {environment} from "../../../../environments/environment";
import {ShareService} from "../../../services/share/share.service";
import {AuthService} from "../../../services/auth/auth.service";
import {UserService} from "../../../services/user/user.service";
import {UserGeolocationService} from "../../../services/geolocation/user-geolocation.service";
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import {ImageManager} from "../../../shared/image-manager";

@Component({
  selector: 'app-xplore-page',
  templateUrl: './xplore-page.component.html',
  styleUrls: ['./xplore-page.component.css']
})
export class XplorePageComponent implements OnInit, OnDestroy {

  @ViewChild("sliderRef")
  sliderRef: ElementRef<HTMLElement>;

  @Input()
  xploreItem: XploreItemDTO;

  slider: KeenSliderInstance = null;

  xploreItems: XploreItemDTO[] = [];
  loadedIndex: number = 0;
  loading: boolean = false;
  errorHappened: boolean = false;

  currentSliderIndex = 0;

  isMobile: boolean = window.innerWidth < 1024;
  private onDestroy$: Subject<void> = new Subject<void>();

  private canChangeUrl: boolean = false;
  private hasOneSelected: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private xploreItemService: XploreItemService,
    private metaDataService: MetaDataService,
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService,
    private territoryService: TerritoryService,
    private shareService: ShareService,
    private authService: AuthService,
    private userService: UserService,
    private userGeolocationService: UserGeolocationService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    if(!this.isMobile) {
      return;
    }

    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
      rubberband: false,
      slides: {
        origin: "center",
        perView: 1,
        spacing: 0,
      },
      vertical: true,
      slideChanged: (s) => {
        this.onSlideChange(s.animator.targetIdx);
      },
      selector: ".first > .keen-slider__slide",
    });

    setTimeout(() => {
      this.slider.update(undefined, this.currentSliderIndex);
      this.cdr.detectChanges(); // Ensure Angular detects the changes
    }, 0);
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.xploreItem) {
      this.xploreItems.push(this.xploreItem);
      this.loading = false;
      this.handleMetaTags(this.xploreItem);
      return;
    }

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.analyticsService.addXploreVisitAction(Number(id))

      this.hasOneSelected = true;
      this.xploreItemService.getXploreItemById(id)
        .pipe(
          takeUntil(this.onDestroy$),
          map(xploreItem => {
            if(!xploreItem) {
              this.router.navigate(['/']);
            }
            this.xploreItems.push(xploreItem);
            this.handleMetaTags(xploreItem);
            this.changeCurrenUrl(xploreItem);
            this.loading = false;
          }),
          catchError(error => {
            this.errorHappened = true
            this.loading = false;
            return EMPTY;
          })
        ).subscribe();
    } else {
      this.handleMetaTags(null);
    }

    this.getXploreItemsPaged();
  }

  private changeCurrenUrl(xploreItem: XploreItemDTO) {

    if(xploreItem.territory.code !== this.territoryService.getTerritoryCodeFromUrl()) {
      if(environment.IS_LOCAL) {
        console.debug("Overwriting territory code")
        return;
      }

      window.location.href = TerritoryService.addTerritoryCodeToUrl(environment.DEFAULT_ITEM_URL + xploreItem.id, xploreItem.territory.code)
    }

    this.location.go(`/explore/${xploreItem.id}/` + xploreItem.name.toLowerCase()
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
      .replace(/ /g, `-`)
      .substring(0, 40));
  }

  onSlideChange(changedSliderIndex) {
    let direction = this.currentSliderIndex < changedSliderIndex ? "next" : "prev";
    let currentXplore = this.xploreItems[changedSliderIndex];
    this.changeCurrenUrl(currentXplore);

    if (changedSliderIndex == this.xploreItems.length - 3 && direction == "next") {
      this.getXploreItemsPaged();
    }
    this.currentSliderIndex = changedSliderIndex;
  }

  private getXploreItemsPaged() {

    this.xploreItemService.getPagedXploreItems(this.loadedIndex).pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (xploreItems) => {
          if (!xploreItems) {
            return;
          }

          this.xploreItems.push(...xploreItems)
          this.loadedIndex++;

          if (!this.hasOneSelected) {
            this.changeCurrenUrl(xploreItems[0]);
          }

          this.loading = false;

          setTimeout(() => this.canChangeUrl = true, 500);

          if(this.isMobile) {
            this.updateKeenSlider();
          }

        },
        error: (error) => {
          this.errorHappened = true
          this.loading = false;
          console.debug(error)
        }
      })
  }

  onScroll() {
    this.getXploreItemsPaged();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 1024;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private handleMetaTags(xploreItem: XploreItemDTO) {
    this.metaDataService.updateMetaDataByXploreItem(xploreItem);
  }

  onXploreInViewport(xploreItemDTO: XploreItemDTO) {
    if (!this.canChangeUrl) {
      return;
    }
    this.changeCurrenUrl(xploreItemDTO);
  }

  getDistanceFromUser(xploreItem: XploreItemDTO) {
    if (!this.userGeolocationService.getDistanceXploreItemFromUserLocation(xploreItem)) {
      return undefined;
    }
    return this.userGeolocationService.getDistanceXploreItemFromUserLocation(xploreItem) + ' km';
  }

  onFavoriteButtonClicked(xploreItem: XploreItemDTO) {
    this.authService.isUserLogged().subscribe(isUserLogged => {
      if (!isUserLogged) {
        this.router.navigate(['login']);
      } else {
        if (!this.userService.checkIfItemInFavorite(xploreItem.id)) {
          this.userService.addSavedXplore(xploreItem.id);
        } else {
          this.userService.removeFavorite(xploreItem.id);
        }
      }
    });
  }

  onImmersiveButtonClicked(xploreItem: XploreItemDTO) {
    this.router.navigate(['immersive/' + xploreItem.id]);
  }

  onSearchTagClicked(tag: TagDTO) {
    this.router.navigate(['search'], { queryParams: { tag: tag.code } });
  }

  onShareButtonClicked(xploreItem: XploreItemDTO) {
    const title = xploreItem.name;
    const text = xploreItem.shortDescription;

    this.shareService.share(xploreItem.id, title, text,
      TerritoryService.addTerritoryCodeToUrl(environment.DEFAULT_ITEM_URL + xploreItem.id, xploreItem.territory.code)
    );
  }

  onUserProfileClicked(userAccount: UserAccountDTO) {
    this.router.navigate(['profile/' + userAccount.userId]);
  }

  isItemFavorite(xploreItem: XploreItemDTO) {
    return this.userService.checkIfItemInFavorite(xploreItem.id);
  }

  get canShare(): boolean {
    return this.shareService.canShare();
  }

  onRedirectToInfoPageClicked(xploreItem: XploreItemDTO) {
    this.router.navigate(['explore/info/' + xploreItem.id]);
  }

  onNavigateToMapClicked(xploreItem: XploreItemDTO) {
    this.router.navigate(['map/' + xploreItem.id]);
  }

  trackById(index: number, item: XploreItemDTO) {
    return item.id;
  }

  private updateKeenSlider(){
    if (this.slider) {
      setTimeout(() => {
        this.slider.update(undefined, this.currentSliderIndex);
        this.cdr.detectChanges();
      }, 120);
    } else {
      console.error('No slider')
    }
  }

  protected readonly ImageManager = ImageManager;
}
