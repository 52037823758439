import { Component, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { ImageManager } from 'src/app/shared/image-manager';
import { MultiMediaDTO, XploreItemDTO } from 'src/app/shared/object/xplorer-be-parsed-classes';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'xplorer-bowser-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css']
})
export class FeaturedComponent implements OnInit {

  @Input() event: XploreItemDTO;

  constructor(private router: Router) { }

  ngOnInit() { }

  getImage(multimediaDTO: MultiMediaDTO) {
    return multimediaDTO?.mediaURL ? environment.IMAGES_URL + multimediaDTO.mediaURL : ImageManager.getPlaceholderImage();
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  getItemUrl(xploreItem: XploreItemDTO) {
    return `/explore/${xploreItem.id}`;
  }
}
