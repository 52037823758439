<div class="hidden lg:block lg:py-[1.5rem] top-0 z-[150] w-100 shadow-[0_4px_16px_rgba(0,0,0,.1)] lg:fixed lg:bg-white">
  <nav class="container mx-auto px-[0.75rem] bg-white">
    <div class="flex flex-wrap items-center justify-between relative gap-x-6 w-full">
      <div class="flex w-1/4">
        <div class="cursor-pointer" routerLink="/">
          <img alt="Xplorer" [src]="ImageManager.getLogoNavbarByTerritoryCode()" width="198" height="47">
        </div>
      </div>

      <div class="w-1/3">
        <search-input (searchButtonClicked)="searchClicked($event)"></search-input>
        <!-- <div class="hidden sm:block">
            <div class="flex items-baseline space-x-4">
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/explore"
                routerLinkActive="header-color disable-hover">
                Home
              </a>
              <a
                class="small-button rounded-md  text-sm font-medium custom-button-inactive"
                routerLink="/explore"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/search"
                routerLinkActive="header-color disable-hover">
                {{'tabs.search' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive" routerLink="/search"
                routerLinkActive="header-color disable-hover">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                     stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/favorites"
                routerLinkActive="header-color disable-hover">
                {{'tabs.favorites' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/favorites"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
              <a
                class="full-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/map"
                routerLinkActive="header-color disable-hover">
                {{'tabs.map' | translate}}
              </a>
              <a
                class="small-button rounded-md text-sm font-medium custom-button-inactive"
                routerLink="/map"
                routerLinkActive="header-color disable-hover">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
          </div> -->

      </div>

      <div class="flex items-center justify-content-end w-1/4 gap-x-4">
        <div class="flex gap-x-2 items-center bg-white custom-border-solid rounded-full py-1 pl-1 pr-1 xl:pr-2 cursor-pointer" routerLink="/explore">
          <div class="rounded-full custom-background-color p-2">
            <svg class="w-[22px] h-[22px]" fill="none" stroke="#fff" stroke-width="2" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <use [attr.xlink:href]="ImageManager.exploreIcon" />
            </svg>
          </div>
          <p class="lg:hidden xl:block xl:text-sm font-medium custom-base-color">{{ 'navbar.explore' | translate }}</p>
        </div>

        <div class="bg-white custom-border-solid rounded-full p-1 cursor-pointer" routerLink="/map">
          <div class="rounded-full custom-background-color p-2">
            <svg class="w-[22px] h-[22px]" fill="none" stroke="#fff" stroke-width="2" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <use [attr.xlink:href]="ImageManager.mapIcon" />
            </svg>
          </div>
        </div>

        <!-- <button (click)="toggleLanguage()" class="h-10 w-10 rounded-full hover:bg-gray-200">
          <div class="h-7 w-7 m-auto rounded-full overflow-hidden">
            <img [src]="flagIcon" alt="language flag" class="h-100 object-cover">
          </div>
        </button> -->

        <div class="group relative dropdown">
          <div class="relative">
            <div *ngIf="!isUserFullyLogged()">
              <img [src]="ImageManager.getProfilePlaceholder()" alt="profile placeholder"
                class="w-[48px] h-[48px]">
            </div>
            <div *ngIf="isUserFullyLogged()">
              <img [src]="ImageManager.getProfilePlaceholder()" alt="profile placeholder"
                class="w-[48px] h-[48px]">
            </div>

            <!-- Menu -->
            <xplorer-bowser-menu-dropdown>
            </xplorer-bowser-menu-dropdown>
          </div>
        </div>

      </div>

    </div>
  </nav>
</div>
