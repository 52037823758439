import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BookSectionComponent} from "./book-section/book-section.component";
import {DescriptionModalComponent} from "./description-modal/description-modal.component";
import {FilterButtonComponent} from "./filter-button/filter-button.component";
import {InfoBoxComponent} from "./info-box/info-box.component";
import {DateInfoItemComponent} from "./info-items/date-info-item/date-info-item.component";
import {EmailInfoItemComponent} from "./info-items/email-info-item/email-info-item.component";
import {HourInfoItemComponent} from "./info-items/hour-info-item/hour-info-item.component";
import {LocationInfoItemComponent} from "./info-items/location-info-item/location-info-item.component";
import {PhoneInfoItemComponent} from "./info-items/phone-info-item/phone-info-item.component";
import {PriceInfoItemComponent} from "./info-items/price-info-item/price-info-item.component";
import {WebsiteInfoItemComponent} from "./info-items/website-info-item/website-info-item.component";
import {ItemCardComponent} from "./item-card/item-card.component";
import {MobileItemCardComponent} from "./mobile-item-card/mobile-item-card.component";
import {PlaceholderCardComponent} from "./placeholder-card/placeholder-card.component";
import {PreviewItemCardComponent} from "./preview-item-card/preview-item-card.component";
import {PwaPopupComponent} from "./pwa-popup/pwa-popup.component";
import {RelatedItemsManagerComponent} from "./related-items-manager/related-items-manager.component";
import {ReservationPopupComponent} from "./reservation-popup/reservation-popup.component";
import {TabComponent} from "./tab/tab.component";
import {TranslateModule} from "@ngx-translate/core";
import {InfoToastComponent} from "../../shared/components/info-toast/info-toast.component";
import {SearchInputComponent} from "../pages/search-page/search-input/search-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { LoadUserComponent } from '../pages/load-user/load-user.component';
import {LongPressDirective} from "../../shared/directive/long-press.directive";
import { LocationSwitchPopupComponent } from './location-switch-popup/location-switch-popup.component';
import {RouterModule, RouterLinkActive} from "@angular/router";
import { InstagramInfoItemComponent } from './info-items/instagram-info-item/instagram-info-item.component';
import { FacebookInfoItemComponent } from './info-items/facebook-info-item/facebook-info-item.component';
import { HeroComponent } from './hero/hero.component';
import { CloseDestinationsComponent } from './close-destinations/close-destinations.component';
import { TerritoryComponent } from './territory/territory.component';
import { FeaturedComponent } from './featured/featured.component';
import { CategoriesComponent } from './categories/categories.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuDropdownComponent } from './menu-dropdown/menu-dropdown.component';

@NgModule({
  declarations: [
    BookSectionComponent,
    DescriptionModalComponent,
    FilterButtonComponent,
    InfoBoxComponent,
    DateInfoItemComponent,
    EmailInfoItemComponent,
    HourInfoItemComponent,
    LocationInfoItemComponent,
    PhoneInfoItemComponent,
    PriceInfoItemComponent,
    WebsiteInfoItemComponent,
    ItemCardComponent,
    MobileItemCardComponent,
    PlaceholderCardComponent,
    PreviewItemCardComponent,
    PwaPopupComponent,
    RelatedItemsManagerComponent,
    ReservationPopupComponent,
    TabComponent,
    InfoToastComponent,
    SearchInputComponent,
    LoadUserComponent,
    LongPressDirective,
    LocationSwitchPopupComponent,
    InstagramInfoItemComponent,
    FacebookInfoItemComponent,
    HeroComponent,
    CloseDestinationsComponent,
    TerritoryComponent,
    FeaturedComponent,
    CategoriesComponent,
    MobileHeaderComponent,
    MenuDropdownComponent
  ],
    imports: [
        NgOptimizedImage,
        NgbModule,
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule,
        RouterLinkActive,
        FormsModule,
    ],
  exports: [
    BookSectionComponent,
    DescriptionModalComponent,
    FilterButtonComponent,
    InfoBoxComponent,
    DateInfoItemComponent,
    EmailInfoItemComponent,
    HourInfoItemComponent,
    LocationInfoItemComponent,
    PhoneInfoItemComponent,
    PriceInfoItemComponent,
    WebsiteInfoItemComponent,
    ItemCardComponent,
    MobileItemCardComponent,
    PlaceholderCardComponent,
    PreviewItemCardComponent,
    PwaPopupComponent,
    RelatedItemsManagerComponent,
    ReservationPopupComponent,
    TabComponent,
    InfoToastComponent,
    SearchInputComponent,
    LongPressDirective,
    LocationSwitchPopupComponent,
    InstagramInfoItemComponent,
    FacebookInfoItemComponent,
    HeroComponent,
    CloseDestinationsComponent,
    TerritoryComponent,
    FeaturedComponent,
    CategoriesComponent,
    MobileHeaderComponent,
    MenuDropdownComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsModule { }
