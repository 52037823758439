import {Component, OnInit, ViewChild} from '@angular/core';
import {ImageManager} from "../../../shared/image-manager";
import {ToastService} from "../../../services/toast/toast.service";
import {ToastTextType} from "../../../shared/object/toast";
import {LocationSwitchPopupComponent} from "../location-switch-popup/location-switch-popup.component";
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-page-tabbar',
  templateUrl: './page-tabbar.component.html',
  styleUrls: ['./page-tabbar.component.css']
})
export class PageTabbarComponent implements OnInit {

  longPressClicked = false;

  @ViewChild('switchPopupComponent') locationSwitchPopupComponent: LocationSwitchPopupComponent;

  constructor(
    private toastr: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  protected readonly ImageManager = ImageManager;

  onLongPress() {
    this.modalService.open();
  }
}
