import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ImageManager } from 'src/app/shared/image-manager';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent implements OnInit {

  protected readonly ImageManager = ImageManager;

  @ViewChild('inputName') input: ElementRef;

  @Output()
  searchButtonClicked: EventEmitter<string> = new EventEmitter();

  @Output()
  isSearchingEmitter = new EventEmitter();

  @Input()
  inputText: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  getName() {
    return this.input.nativeElement.value
  }

  buttonClicked() {
    this.searchButtonClicked.emit(this.inputText)
  }

  emitEvent(event) {
    this.isSearchingEmitter.emit(event)
  }
}
