import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit} from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { environment } from "../../../../environments/environment";
import { TerritoryService } from "../../../services/geolocation/territory.service";
import { ImageManager } from "../../../shared/image-manager";

@Component({
  selector: 'xplorer-bowser-location-switch-popup',
  templateUrl: './location-switch-popup.component.html',
  styleUrls: ['./location-switch-popup.component.css'],
  animations: [
    trigger('fadeBackground', [
      state(
        'hidden',
        style({
          backgroundColor: 'rgba(0, 0, 0, 0)',
          position: 'relative',
        })
      ),
      state(
        'visible',
        style({
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'absolute',
        })
      ),
      transition('hidden <=> visible', animate('150ms ease-in-out')),
    ]),
  ],
})
export class LocationSwitchPopupComponent implements OnInit {

  isVisible = false;
  isAnimating = false;

  availableTerritories: any[] = environment.AVAILABLE_TERRITORY;

  constructor(
    private modalService: ModalService,
    private territoryService: TerritoryService  
  ) { }

  ngOnInit(): void {
    this.modalService.isVisible$.subscribe((visible) => {
      this.isVisible = visible;
    });
  }

  toggleModal(): void {
    this.isVisible = !this.isVisible;
  }

  closeModal(): void {
    this.isVisible = false;
  }

  getActiveTerritory() {
    this.availableTerritories.filter((territory) => {
      if (territory.code === this.territoryService.getTerritoryCodeFromUrl()) {
        return territory;
      }
    });
  }

  protected readonly ImageManager = ImageManager;

  getCurrentTerritoryCode() {
    return this.territoryService.getTerritoryCodeFromUrl();
  }

  changeTerritory(selectedTerritory: any) {
    if (selectedTerritory.code === this.getCurrentTerritoryCode()) {
      return;
    }

    console.log("Territory change initiated:", selectedTerritory);

    window.location.href = selectedTerritory.url;
  }

}
