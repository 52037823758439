import { Component, OnInit } from '@angular/core';
import { ImageManager } from 'src/app/shared/image-manager';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../../services/auth/auth.service";
import { Router } from "@angular/router";
import { Filter } from 'src/app/shared/object/filter';
import { FilterService } from 'src/app/services/filter.service';
import { XploreItemService } from 'src/app/services/xplore-item.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  filters: Filter[] = [];
  loadedIndex = 0;
  isSearchPage: boolean = false;


  protected readonly ImageManager = ImageManager;

  flagIcon: string;

  constructor(
    private translation: TranslateService,
    private authService: AuthService,
    private xploreItemService: XploreItemService,
    private router: Router,
    private filterService: FilterService
  ) {
    this.filters = filterService.getFilters();
  }

  ngOnInit(): void {
    this.updateFlagIcon(this.translation.currentLang);

    this.router.events.subscribe(() => {
      this.isSearchPage = this.router.url.includes('/search');
    });
  }

  toggleLanguage(): void {
    const selectedLanguage = this.translation.currentLang === 'it' ? 'en' : 'it'
    this.changeLanguage(selectedLanguage)
  }

  changeLanguage(language: string): void {
    this.translation.use(language);
    this.updateFlagIcon(language);
    localStorage.setItem('language', language);
  }

  updateFlagIcon(currentLang: string): void {
    this.flagIcon = currentLang === 'it' ? ImageManager.italianFlagIcon : ImageManager.englishFlagIcon;
  }

  isUserFullyLogged() {
    return this.authService.isUserLogged();
  }

  searchClicked(inputText: string) {
    if(inputText === '') {
      this.router.navigate(['search']);
    } else {
      this.router.navigate(['search'], { queryParams: { name: inputText } });
    }
  }
}
