<div class="container mx-auto px-[0.75rem] pt-6">
    <div class="flex justify-between items-center">
        <div class="flex gap-[16px] flex items-center">
            <img [src]="ImageManager.getLogoHeaderMobileByTerritoryCode()" alt="Logo" width="49">
            <div>
                <p class="text-sm pb-1">{{ 'home.header_mobile.position' | translate }}</p>
                <div class="flex items-center gap-x-1 cursor-pointer" (click)="onLocationClick()">
                    <p class="font-semibold text-xl custom-base-color">{{ currentTerritoryName }}</p>
                    <svg class="w-6 h-6 custom-base-color" fill="none" stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <use [attr.xlink:href]="ImageManager.arrowDown" />
                    </svg>
                </div>
            </div>
        </div>
        <a routerLink="/profile" class="w-10 h-[41px] rounded-full">
            <img [src]="ImageManager.getProfilePlaceholder()" alt="" class=" object-cover object-center">
        </a>
    </div>
</div>
