import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AnalyticsDTO} from "../../shared/object/xplorer-be-parsed-classes";
import {environment} from "../../../environments/environment";
import {
  Action,
  Touchpoint,
  UserAction,
  UserImmersiveUseAction,
  UserLikeAction,
  UserLoginAction, UserLoginKeycloakAction,
  UserMapUseAction,
  UserShareAction,
  UserSignupAction,
  UserVisitAction,
  UserXploreVisitAction
} from "../../shared/object/xplorer-analytics-devourer-parsed-classes";
import {TokenStorageService} from "../auth/token-storage.service";
import {TerritoryService} from "../geolocation/territory.service";
import {share} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(
    private readonly http: HttpClient,
    private readonly tokenStorageService: TokenStorageService,
    private readonly territoryService: TerritoryService
  ) {
  }

  addUserAction(userId: string, action: Action) {

  }

  saveLikeAction(xploreId: number) {
    let basicAction: UserAction = this.getBasicAction("SHARE");

    const likeAction: UserLikeAction = {
      ...basicAction,
      xploreId: xploreId
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/like`, likeAction)
      .subscribe();
  }

  addShareAction(xploreId: number) {
    let basicAction: UserAction = this.getBasicAction("SHARE");

    const shareAction: UserShareAction = {
      ...basicAction,
      xploreId: xploreId
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/share`, shareAction)
      .subscribe();
  }


  addXploreVisitAction(xploreId: number) {

    let basicAction: UserAction = this.getBasicAction("XPLORE_VISIT");

    const xploreVisitAction: UserXploreVisitAction = {
      ...basicAction,
      xploreId: xploreId,
      spentTime: 0
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/xplore-visit`, xploreVisitAction)
      .subscribe();
  }

  addVisitAction() {

    let basicAction: UserAction = this.getBasicAction("VISIT");

    const visitAction: UserVisitAction = {
      ...basicAction,
      spentTime: 0
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/visit`, visitAction)
      .subscribe();
  }

  addImmersiveUseAction(xploreId: number) {

    let basicAction: UserAction = this.getBasicAction("IMMERSIVE_USE");

    const userImmersiveUseAction: UserImmersiveUseAction = {
      ...basicAction,
      xploreId: xploreId,
      spentTime: 0
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/immersive-use`, userImmersiveUseAction)
      .subscribe();
  }

  addMapUseAction() {

    let basicAction: UserAction = this.getBasicAction("MAP_USE");

    const mapUseAction: UserMapUseAction = {
      ...basicAction
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/map-use`, mapUseAction)
      .subscribe();
  }

  addLoginAction() {

    let basicAction: UserAction = this.getBasicAction("LOGIN");

    const loginAction: UserLoginAction = {
      ...basicAction
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/login`, loginAction)
      .subscribe();
  }

  addSignupAction(email: string) {

    let basicAction: UserAction = this.getBasicAction("SIGNUP");

    const signupAction: UserSignupAction = {
      ...basicAction,
      userEmail: email,
      userId: -1
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/signup`, signupAction)
      .subscribe();
  }

  addLoginKeycloakAction() {

    let basicAction: UserAction = this.getBasicAction("LOGIN_KEYCLOAK");

    const loginKeycloakAction: UserLoginKeycloakAction = {
      ...basicAction
    }

    this.http.post<number>(`${environment.ANALYTICS_DEVOURER_API_URL}/user-actions/login-keycloak`, loginKeycloakAction)
      .subscribe();
  }


  private retrieveUserId() {
    let userId: number;
    let user = this.tokenStorageService.getUser();
    if (user) {
      userId = user.userId;
    } else {
      userId = -1;
    }
    return userId;
  }

  private retrieveUserEmail() {
    let userEmail: string;
    let user = this.tokenStorageService.getUser();
    if (user) {
      userEmail = user.email;
    } else {
      userEmail = "";
    }
    return userEmail;
  }

  private getBasicAction(actionTye: Action) {

    const shareAction: UserAction = {
      id: 0,
      userId: this.retrieveUserId(),
      userEmail: this.retrieveUserEmail(),
      actionTime: new Date(),
      touchpoint: this.getTouchpoint(),
      territoryCode: this.territoryService.getTerritoryCodeFromUrl(),
      userActionType: actionTye
    }

    return shareAction;
  }

  private getTouchpoint(): Touchpoint {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return "PWA";
    }

    if (window.matchMedia("(max-width: 767px)").matches) {
      return "WEB_MOBILE";
    } else {
      return "WEB_DESKTOP";
    }
  }

}
