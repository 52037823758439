<div class="w-full no-scrollbar overflow-x-auto lg:overflow-x-hidden snap-mandatory snap-x flex flex-nowrap lg:grid lg:grid-cols-5 px-[0.75rem] gap-[12px] lg:gap-6">

    <!-- Cards -->
    <div *ngFor="let xploreItem of displayedXploreItems"
        class="flex-none inline-flex items-end w-[120px] sm:w-[142px] md:w-[174px] lg:w-auto relative h-[213px] sm:h-64 md:h-72 lg:h-[18rem] xl:h-96 2xl:h-[31rem] rounded-xl lg:rounded-3xl cursor-pointer snap-center snap-always"
        (click)="navigateTo(getItemUrl(xploreItem))">
        <img [src]="getItemImageUrl(xploreItem)" [alt]="xploreItem.name"
            class="absolute inset-0 h-full w-full object-cover rounded-xl lg:rounded-3xl">
        <div
            class="py-[1rem] px-[0.5rem] md:py-6 md:px-4 xl:py-12 xl:px-6 bg-gradient-to-b from-transparent to-black rounded-b-xl lg:rounded-b-3xl relative z-10 w-full">
            <div class="mb-[0.5rem] lg:mb-[1rem] w-full">
                <h2 class="text-white font-semibold text-sm sm:text-base lg:text-xl 2xl:text-2xl line-clamp-2">
                    {{ xploreItem.name }}
                </h2>
            </div>
            <p class="text-white text-xs sm:text-sm lg:text-base line-clamp-1">
                {{ getItemLocation(xploreItem) }}
            </p>
        </div>
    </div>
</div>
