<div
    class="w-full px-[0.75rem] no-scrollbar overflow-x-auto lg:overflow-x-hidden snap-mandatory snap-x flex flex-nowrap lg:grid lg:grid-cols-6 gap-[12px] lg:gap-6">
    <div *ngFor="let category of displayedCategories"
        class="flex-none w-[120px] sm:w-[142px] md:w-[174px] lg:w-auto rounded-xl lg:rounded-3xl relative h-28 lg:h-40 xl:h-[232px] inline-flex items-end overflow-hidden cursor-pointer snap-center snap-always group"
        (click)="navigateTo(category)">
        <img [src]="ImageManager.getCategoryImageByTerritoryCode(category.imageName)" [alt]="category.name"
            class="absolute inset-0 h-full w-full object-cover object-center transition-transform duration-500 transform group-hover:scale-110">
        <div
            class="py-[12px] px-[0.5rem] md:py-6 md:px-4 xl:py-6 xl:px-6 bg-gradient-to-b from-transparent to-black rounded-b-xl lg:rounded-b-3xl relative z-10 w-full">
            <div class="w-full">
                <h3
                    class="text-white font-semibold text-sm sm:text-base lg:text-xl 2xl:text-2xl line-clamp-2 text-center">
                    {{ 'filters.' + category.name | translate }}</h3>
            </div>
        </div>
    </div>
</div>
