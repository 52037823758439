const HOST_URL= 'https://api.xplorer-platform.com';
const MANAGER_FRONTEND_URL = 'https://manager.xplorer-platform.com/';
const BOWSER_FRONTEND_URL = 'https://xplorer-platform.com';
const POST_LOGIN_REDIRECT_URL = '?redirect_uri=' + BOWSER_FRONTEND_URL + '/login';
const OAUTH2_URL= HOST_URL + '/oauth2/authorization/';
const KEYCLOAK_URL = 'https://auth.xplorer-platform.com'
const LABEL_URL = 'https://label-api.xplorer-platform.com';
const ANALYTICS_DEVOURER_URL = 'https://analytics.xplorer-platform.com';

export const environment = {
  IS_LOCAL: false,
  UNSECURE: false,
  IS_PRODUCTION: true,
  HOST_URL: HOST_URL,
  API_URL: HOST_URL + '/api/v1',
  IMAGES_URL: 'https://media.xplorer-platform.com/xplore_media/',
  AUTH_API_URL: HOST_URL + '/api/v1/auth',
  GOOGLE_AUTH_URL: OAUTH2_URL + 'google' + POST_LOGIN_REDIRECT_URL,
  FACEBOOK_AUTH_URL: OAUTH2_URL + 'facebook' + POST_LOGIN_REDIRECT_URL,
  IS_WEBAPP: true,
  VERSION: '1.12 - PROD',
  DEFAULT_ITEM_URL: BOWSER_FRONTEND_URL + '/explore/',
  KEYCLOAK_URL: KEYCLOAK_URL,
  KEYCLOAK_REALM: 'xplorer',
  KEYCLOAK_CLIENT_ID: 'xplorer-suite',
  KEYCLOAK_TOKEN_URL: `${KEYCLOAK_URL}/realms/xplorer/protocol/openid-connect/token`,
  FRONTEND_BASE_URL: BOWSER_FRONTEND_URL,
  LABEL_URL: LABEL_URL + '/api/v1',
  ANALYTICS_DEVOURER_API_URL: ANALYTICS_DEVOURER_URL + '/api/v1',
  AVAILABLE_TERRITORY: [
    {
      "id": 1,
      "code": "chianti",
      "name": "Chianti",
      "url": "https://chianti.xplorer-platform.com"
    },
    {
      "id": 2,
      "code": "mugello",
      "name": "Mugello",
      "url": "https://mugello.xplorer-platform.com"
    }
  ]
};
