import { Component, OnInit, HostListener, OnDestroy, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MultiMediaDTO, XploreItemDTO } from 'src/app/shared/object/xplorer-be-parsed-classes';
import { environment } from "../../../../environments/environment";
import { ImageManager } from "../../../shared/image-manager";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'xplorer-bowser-close-destinations',
  templateUrl: './close-destinations.component.html',
  styleUrls: ['./close-destinations.component.css']
})

export class CloseDestinationsComponent implements OnInit {

  displayedXploreItems: XploreItemDTO[];
  screenWidth: number;

  @Input() allXploreItems: XploreItemDTO[];
  @Input() loading: boolean;

  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.updateDisplayedCards();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allXploreItems'] && this.allXploreItems) {
      this.updateDisplayedCards();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDisplayedCards();
  }

  updateDisplayedCards() {
    if (this.allXploreItems && this.allXploreItems.length > 0) {
      const limit = this.getCardLimit();
      this.displayedXploreItems = this.allXploreItems.slice(0, limit);
    }
  }

  getCardLimit(): number {
    return window.innerWidth < 1024 ? 5 : 5;
  }

  getImage(multimediaDTO: MultiMediaDTO) {
    return multimediaDTO?.mediaURL ? environment.IMAGES_URL + multimediaDTO.mediaURL : ImageManager.getPlaceholderImage();
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  getItemImageUrl(xploreItem: XploreItemDTO) {
    return (xploreItem.multiMedia && xploreItem.multiMedia.length > 0)
      ? this.getImage(xploreItem.multiMedia[0])
      : ImageManager.getPlaceholderImage();
  }

  getItemUrl(xploreItem: XploreItemDTO) {
    return `/explore/${xploreItem.id}`;
  }

  getItemLocation(xploreItem: XploreItemDTO) {
    const locationName = xploreItem.geoPositionDTO?.location?.comune?.name;
    return locationName ? locationName : this.translate.instant('home.location_unavailable');
  }
}
