import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../services/auth/auth.service";
import { environment } from "../../../../environments/environment";
import { ToastService } from "../../../services/toast/toast.service";
import { ToastTextType } from "../../../shared/object/toast";
import { MetaDataService } from "../../../services/meta/meta-data.service";
import { TokenStorageService } from "../../../services/auth/token-storage.service";
import {ImageManager} from "../../../shared/image-manager";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {LoginRequest} from "../../../shared/object/xplorer-be-parsed-classes";

@Component({
  selector: 'xplorer-bowser-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
  isShowing = false;
  loginForm: FormGroup;
  source: string = undefined;

  isFromThirdParty: boolean = navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("facebook") || navigator.userAgent.includes("Linkedin");

  isLoadingUser: boolean = true;
  isLoading: boolean;
  errorTextCode: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translation: TranslateService,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private toastService: ToastService,
    private metaDataService: MetaDataService,
    private analyticsService: AnalyticsService,
  ) {
    this.source = this.router.getCurrentNavigation()?.extras?.state?.['source'];
  }

  ngOnInit() {
    this.isLoadingUser = false;
    this.buildForm();
    this.metaDataService.setDefaultMetaData();
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    const {email, password} = this.loginForm.value
    const loginRequest: LoginRequest = {
      password: password,
      email: email
    }

    this.authService.login(loginRequest.email, loginRequest.password)
      .subscribe({
        next: loginResponse => {

          this.tokenStorageService.saveRefreshToken(loginResponse.refreshToken)
          this.tokenStorageService.saveUser(loginResponse.user);
          this.tokenStorageService.saveToken(loginResponse.token);

          this.toastService.showSuccessToast(ToastTextType.LOGIN_SUCCESSFUL);
          this.analyticsService.addLoginAction();

          this.authService.initKeycloakWithNewToken(loginResponse.token, loginResponse.refreshToken);

          if (this.source) {
            this.router.navigate([this.source])
          } else {
            this.router.navigate(['profile']);
          }
        },
        error: error => {
          this.isLoading = false;
          console.debug("Error code: ", error);
          if (error.status === 401) {
            this.showErrorAlert("login.error.wrong_password")
          } else if (error.status === 404) {
            this.showErrorAlert("login.error.no_account")
          } else {
            this.errorTextCode = 'login.error.text';
          }
        }
      });
  }

  public showErrorAlert(errorTextCode?: string) {
    if(errorTextCode) {
      this.errorTextCode = errorTextCode;
    } else {
      this.errorTextCode = 'login.error.text';
    }
    this.isShowing = true
    setTimeout(() => {
      this.staticAlert.close()
    }, 6000);
  }

  keycloakLogin = () => {
    this.authService.googleLogin(this.source);
  }

  getTermOfServiceUrl() {
    const currentLanguage = this.translation.currentLang;
    return 'https://xplorer-platform.com/' + currentLanguage + '/tos/'
  }

  //TODO Maybe dont use form here
  private buildForm() {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  goToRegister() {
    if(this.source) {
      this.router.navigate(['signup'], {state: {source: this.source}});
    } else {
      this.router.navigate(['signup']);
    }
  }

  backToHome() {
    this.router.navigate(['']);
  }

  protected readonly ImageManager = ImageManager;

}
