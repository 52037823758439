<!-- Modal Background -->
<div [@fadeBackground]="isVisible ? 'visible' : 'hidden'" class="inset-0 z-50 lg:z-[160] sm:flex sm:items-center sm:justify-center" (click)="closeModal()">

  <!-- Modal Content -->
  <div [ngClass]="{'': isVisible, 'translate-y-full': !isVisible}" (click)="$event.stopPropagation()"
    class="fixed bottom-[0] sm:bottom-auto w-full sm:max-w-md transition duration-300 ease-in-out transform translate-y-full sm:flex sm:flex-col sm:justify-center">

    <div class="px-6 py-16 bg-gradient-to-r from-[#ffffff] to-[#EDF2FB] rounded-t-3xl sm:rounded-3xl relative">
      <button (click)="closeModal()" class="absolute right-6 top-6">
        <svg class="w-[20px] h-[20px] text-[#1d1d1ba6]" fill="none" stroke="currentColor" stroke-width="2"
          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <p class="font-medium text-small pb-6">{{ 'dropdown_menu.change_territory' | translate }}</p>

      <div class="bg-white shadow-[0_4px_16px_rgba(0,0,0,.1)] rounded-2xl">
        <div *ngFor="let availableTerritory of availableTerritories; let i = index" class="relative">
          <div class="flex items-center justify-between px-6 py-[1rem] relative pointer-events-auto">
            <div class="flex items-center gap-x-4 cursor-pointer" (click)="changeTerritory(availableTerritory)">
              <img alt="Explore icon" class="h-10"
                [src]="ImageManager.getLogoModalByTerritoryCode(availableTerritory.code)">
              <p class="font-semibold">Xplorer {{ availableTerritory.name }}</p>
            </div>

            <div *ngIf="availableTerritory.code === getCurrentTerritoryCode()">
              <svg class="w-6 h-6 custom-base-color" fill="none" stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <use [attr.xlink:href]="ImageManager.checkCircleIcon" />
              </svg>
            </div>
          </div>

          <div *ngIf="i < availableTerritories.length - 1" class="h-[1px] bg-[#DCE4E7] w-full"></div>
        </div>
      </div>
    </div>
  </div>
</div>