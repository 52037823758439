<div class="overflow-y-auto height-without-bar lg:h-full">
    <div class="lg:hidden">
        <xplorer-bowser-mobile-header></xplorer-bowser-mobile-header>
    </div>
    <div class="lg:mt-40 ">
        <div class="container mx-auto px-0">
            <div class="px-[0.75rem]">
                <xplorer-bowser-hero></xplorer-bowser-hero>
            </div>

            <div class="lg:mt-20">
                <div class="mb-6 md:mb-12 px-[0.75rem]">
                    <ng-container *ngIf="loadingTitle; else titleContent">
                        <div
                            class="mb-6 md:mb-12 w-48 h-8 lg:w-64 lg:h-10 2xl:w-72 2xl:h-12 bg-gray-300 rounded animate-pulse">
                        </div>
                        <div
                            class="w-full no-scrollbar overflow-x-auto lg:overflow-x-hidden snap-mandatory snap-x flex flex-nowrap lg:grid lg:grid-cols-5 gap-[12px] lg:gap-6">
                            <div *ngFor="let skeleton of skeletons"
                                class="flex-none inline-flex items-end w-[120px] sm:w-[142px] md:w-[174px] lg:w-auto rounded-xl lg:rounded-3xl relative h-[213px] sm:h-64 md:h-72 lg:h-[18rem] xl:h-96 2xl:h-[31rem] overflow-hidden animate-pulse bg-gray-300 snap-center snap-always">
                                <div class="absolute inset-0 bg-gradient-to-b from-gray-300 to-gray-400"></div>
                                <div
                                    class="relative z-10 w-full py-[1rem] px-[0.5rem] md:py-6 md:px-4 xl:py-12 xl:px-6">
                                    <div class="mb-[0.5rem] lg:mb-[1rem] w-full h-6 lg:h-8 bg-gray-500 rounded"></div>
                                    <div class="w-2/3 h-4 lg:h-6 bg-gray-500 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #titleContent>
                        <h2 class="text-black font-semibold text-[28px] lg:text-4xl 2xl:text-5xl">{{ sectionTitle }}
                        </h2>
                    </ng-template>
                </div>

                <xplorer-bowser-close-destinations [allXploreItems]="allXploreItems" [loading]="loading">
                </xplorer-bowser-close-destinations>
            </div>

            <div class="mt-12 lg:mt-20 px-[0.75rem]">
                <div class="mb-6 md:mb-12">
                    <h2 class="text-black font-semibold text-[28px] lg:text-4xl 2xl:text-5xl">{{ 'home.our_territories'
                        |
                        translate }}</h2>
                </div>
                <xplorer-bowser-territory></xplorer-bowser-territory>
            </div>

            <div class="mt-12 lg:mt-20 px-[0.75rem]">
                <div *ngIf="!loadingFeaturedEvent && featuredEvent">
                    <div class="mb-6 md:mb-12">
                        <h2 class="text-black font-semibold text-[28px] lg:text-4xl 2xl:text-5xl">{{ 'home.featured' |
                            translate }}
                        </h2>
                    </div>
                    <xplorer-bowser-featured [event]="featuredEvent"></xplorer-bowser-featured>
                </div>

                <!-- Skeleton Loader -->
                <ng-container *ngIf="loadingFeaturedEvent">
                    <div
                        class="mb-6 md:mb-12 w-48 h-8 lg:w-64 lg:h-10 2xl:w-72 2xl:h-12 bg-gray-300 rounded animate-pulse">
                    </div>
                    <div
                        class="w-full h-40 md:h-56 lg:h-96 bg-gray-300 rounded-xl lg:rounded-3xl overflow-hidden relative animate-pulse">
                        <div
                            class="w-full h-full object-cover absolute inset-0 bg-gradient-to-b from-gray-300 to-gray-400 rounded-xl lg:rounded-3xl">
                        </div>
                        <div
                            class="relative z-10 flex items-center justify-center flex-col h-full gap-[0.5rem] lg:gap-6 py-[1rem] px-6">
                            <div class="w-1/2 lg:w-1/4 h-8 md:h-10 lg:h-12 bg-gray-500 rounded mb-2 lg:mb-4"></div>
                            <div class="w-1/3 lg:w-1/6 h-4 lg:h-6 bg-gray-500 rounded"></div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="mt-12 pb-12 lg:mt-20 lg:pb-20">
                <div class="mb-6 md:mb-12 flex justify-between items-center px-[0.75rem]">
                    <h2 class="text-black font-semibold text-[28px] lg:text-4xl 2xl:text-5xl">{{ 'home.categories' |
                        translate
                        }}</h2>
                </div>
                <xplorer-bowser-categories></xplorer-bowser-categories>
            </div>
        </div>
    </div>

</div>