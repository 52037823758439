import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {XplorePageComponent} from './views/pages/xplore-page/xplore-page.component';
import {SearchPageComponent} from './views/pages/search-page/search-page.component';
import {XploreItemInfoPageComponent} from "./views/pages/xplore-item-info-page/xplore-item-info-page.component";
import {LoginPageComponent} from "./views/pages/login-page/login-page.component";
import {SignupPageComponent} from "./views/pages/signup-page/signup-page.component";
import {XploreItemPreviewComponent} from "./views/debug/xplore-item-preview/xplore-item-preview.component";
import {AuthGuard} from "./services/guard/auth.guard";
import {AdminAuthGuard} from "./services/guard/admin.guard";
import {FavoritesPageComponent} from "./views/pages/favorites-page/favorites-page.component";
import {LogoutPageComponent} from "./views/pages/logout-page/logout-page.component";
import {LoadUserComponent} from "./views/pages/load-user/load-user.component";
import { HomeComponent } from './views/pages/home/home.component';

const routes: Routes = [
  { path: 'explore/info/:id', component: XploreItemInfoPageComponent },
  { path: 'explore/:id', component: XplorePageComponent },
  { path: 'explore/:id/:metaName', component: XplorePageComponent },
  { path: 'explore', component: XplorePageComponent },
  { path: 'search', component: SearchPageComponent },
  { path: 'login', component: LoginPageComponent, canActivate: [AuthGuard] },
  { path: 'load-user', component: LoadUserComponent },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'favorites', component: FavoritesPageComponent,
    canActivate: [AuthGuard] },
  { path: '', component: HomeComponent },
  { path: 'debug/preview', component: XploreItemPreviewComponent,
    canActivate: [AdminAuthGuard] },
  { path: 'map',
    loadChildren: () => import('./views/pages/map/map.module').then(m => m.MapModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map/:id',
    loadChildren: () => import('./views/pages/map/map.module').then(m => m.MapModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'immersive/:xploreId',
    loadChildren: () => import('./views/pages/immersive/immersive.module').then(m => m.ImmersiveModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'label', loadChildren: () => import('./views/pages/label/label.module').then(m => m.LabelModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
