import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { SignUpRequest } from "../../../shared/object/xplorer-be-parsed-classes";
import { AuthService } from "../../../services/auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { MetaDataService } from "../../../services/meta/meta-data.service";
import {ImageManager} from "../../../shared/image-manager";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {TokenStorageService} from "../../../services/auth/token-storage.service";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'xplorer-bowser-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.css']
})
export class SignupPageComponent implements OnInit {
  @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
  errorTextCode: string;
  isConfirmAlertShowing = false;
  isErrorAlertShowing = false;
  signupForm: FormGroup;

  readonly googleLoginUrl = environment.GOOGLE_AUTH_URL;
  readonly facebookLoginUrl = environment.FACEBOOK_AUTH_URL;
  source: string = undefined;
  isLoading: boolean;
  isFromThirdParty: boolean = navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("facebook") || navigator.userAgent.includes("Linkedin");

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private translation: TranslateService,
    private metaDataService: MetaDataService,
    private analyticsService: AnalyticsService,
    private tokenStorageService: TokenStorageService,
    private keycloak: KeycloakService
  ) {
    this.source = this.router.getCurrentNavigation()?.extras?.state?.['source'];
  }

  ngOnInit() {
    this.checkAuthStatus();
    this.buildForm();
    this.metaDataService.setDefaultMetaData();
  }

  onSubmit() {

    if(this.signupForm.invalid) {
      return;
    }

    const {
      password,
      passwordConfirm: passwordConfirm,
      email,
      username} = this.signupForm.value

    let signUpRequest: SignUpRequest = {
      username: email,
      authProvider: "LOCAL",
      displayName: username,
      email: email,
      matchingPassword: passwordConfirm,
      password: password,
      providerUserId: "",
      userID: 0
    }

    this.isLoading = true;

    if (password !== passwordConfirm) {
      this.showError('register.passwords_not_match');
    } else if (password.length < 6 || passwordConfirm.length < 6) {
      this.showError('register.password_not_long_enough');
    } else {

      this.analyticsService.addSignupAction(email);

      this.authService.register(signUpRequest).subscribe({
        next: (response) => {
          this.authService.login(email, password).subscribe({
            next: (response) => {
              this.tokenStorageService.saveRefreshToken(response.refreshToken)
              this.tokenStorageService.saveUser(response.user);
              this.tokenStorageService.saveToken(response.token);

              this.authService.initKeycloakWithNewToken(response.token, response.refreshToken);

              this.analyticsService.addLoginAction()

              this.showSuccessAlert();
              if (this.source) {
                this.router.navigate([this.source])
              } else {
                this.router.navigate(['profile']);
              }
            },
            error: (error) => {
              if(error.status === 401) {
                this.showError('login.error.wrong_password');
              } else if(error.status === 404) {
                this.showError('login.error.no_account');
              } else {
                console.error("Error during registration", error);
                this.showError();
              }
            }
          })
        },
        error: (error) => {
          if (error.status === 409) {
            this.showError('register.error.duplicate_email');
          } else {
            console.error("Error during registration", error);
            this.showError();
          }
        }
      });
    }
  }

  public showError(errorTextCode?: string) {
    this.isLoading = false;
    this.errorTextCode = errorTextCode || "register.error.text";
    this.isErrorAlertShowing = true;
    setTimeout(() => {
      this.staticAlert.close()
    }, 5000);
  }

  public showSuccessAlert() {
    this.isConfirmAlertShowing = true
    setTimeout(() => {
      this.staticAlert.close()
    }, 5000);
  }

  private buildForm() {
    this.signupForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      passwordConfirm: ["", Validators.required],
      username: ["", Validators.required]
    });
  }

  private checkAuthStatus() {
    this.authService.isUserLogged()
        .subscribe({
          next: (isUserLogged: boolean) => {
            if (isUserLogged) {
              console.debug("[AUTH] User logged", this.authService.getUser())
              this.router.navigate(['/profile']);
            }
          }
        })
  }

  loginWithGoogle() {
    this.authService.googleLogin(this.source);
  }

  loginWithFacebook() {
    window.location.href = this.facebookLoginUrl + (this.source ? '?redirect=' + this.source : '');
  }

  getTermOfServiceUrl() {
    const currentLanguage = this.translation.currentLang;
    return 'https://xplorer-platform.com/'+ currentLanguage +'/tos/'
  }

  backToHome() {
    this.router.navigate(['']);
  }

  protected readonly ImageManager = ImageManager;
}
