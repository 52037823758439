<div class="height-without-bar lg:h-full">
  <div *ngIf="isMobile" class="w-full h-full relative">
    <div class="keen-slider h-full first" #sliderRef>
      <div class="keen-slider__slide" *ngFor="let xploreItem of xploreItems">
        <app-mobile-item-card [xploreItem]="xploreItem" [isFavorite]="isItemFavorite(xploreItem)"
                              [canShare]="canShare" [distanceFromUser]="getDistanceFromUser(xploreItem)"
                              (favoriteButtonClickedEvent)="onFavoriteButtonClicked(xploreItem)"
                              (immersiveButtonClickedEvent)="onImmersiveButtonClicked(xploreItem)"
                              (searchTagClickedEvent)="onSearchTagClicked($event)"
                              (shareButtonClickedEvent)="onShareButtonClicked(xploreItem)"
                              (userProfileClickedEvent)="onUserProfileClicked($event)"
                              (redirectToInfoPageClickedEvent)="onRedirectToInfoPageClicked(xploreItem)"
                              (navigateToMapClickedEvent)="onNavigateToMapClicked(xploreItem)">
        </app-mobile-item-card>
      </div>
    </div>
  </div>

  <div class="lg:mt-[120px]">
    <div *ngIf="!isMobile" (scrolled)="onScroll()" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
         [scrollWindow]="false" class="hidden h-100 overflow-y-auto md:block lg:px-8 " infiniteScroll>
      <div *ngFor="let xploreItem of xploreItems; trackBy: trackById" inViewport [inViewportOptions]="{threshold: 1}"
           (inViewportAction)="onXploreInViewport(xploreItem)"
           class="mx-auto lg:mb-12 rounder-l shadow-lg w-3/4 lg:w-3/5 xl:w-1/2">
        <app-item-card [xploreItem]="xploreItem" [isFavorite]="isItemFavorite(xploreItem)" [canShare]="canShare"
                       [distanceFromUser]="getDistanceFromUser(xploreItem)"
                       (favoriteButtonClickedEvent)="onFavoriteButtonClicked(xploreItem)"
                       (immersiveButtonClickedEvent)="onImmersiveButtonClicked(xploreItem)"
                       (searchTagClickedEvent)="onSearchTagClicked($event)"
                       (shareButtonClickedEvent)="onShareButtonClicked(xploreItem)"
                       (userProfileClickedEvent)="onUserProfileClicked($event)"></app-item-card>
      </div>
    </div>
  </div>
</div>
