import {Component, OnInit} from '@angular/core';
import { FilterService } from './services/filter.service';
import { TranslateService } from "@ngx-translate/core";
import { PwaService } from './services/pwa.service';
import { filter } from 'rxjs/operators'
import { UserService } from "./services/user/user.service";
import { NavigationEnd, Router } from "@angular/router";
import { ArgType } from './shared/enums/arg-type';
import { AppSetting } from "./app.setting";
import { AuthService } from "./services/auth/auth.service";
import { MetaDataService } from "./services/meta/meta-data.service";
import {AnalyticsService} from "./services/analytics/analytics.service";
import {ThemeService} from "./services/theme.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'xplorer-bowser2.0';
  showModal: boolean;
  popupModalTimeout: NodeJS.Timeout;
  deferredPrompt: any;
  isLoadingUser = true;
  showNavigation: boolean;
  showHeader: boolean;
  isMobile: boolean = window.innerWidth < 768;
  isBadUserAgent: boolean;

  private readonly navigationHiddenRoutes: string[] = ['/login', '/signup', '/load-user', '/label'];
  private readonly headerHiddenRoutes: string[] = ['/login', '/signup', '/load-user', '/label', '/explore'];

  constructor(
    private translation: TranslateService,
    private filtersService: FilterService,
    private authService: AuthService,
    private pwaService: PwaService,
    private userService: UserService,
    private router: Router,
    private readonly appSetting: AppSetting,
    private metaDataService: MetaDataService,
    private analyticsService: AnalyticsService,
    private themeService: ThemeService
  ) {
    this.initializeApp();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = event.urlAfterRedirects;

      this.showNavigation = !this.isUrlInPaths(currentUrl, this.navigationHiddenRoutes);
      this.showHeader = !this.isUrlInPaths(currentUrl, this.headerHiddenRoutes);

      this.checkArguments();
    });
    this.showModal = false;
    this.isBadUserAgent = navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("facebook") || navigator.userAgent.includes("Linkedin");
  }

  ngOnInit(): void {
    this.authService.clearTokenIfExpired();
    this.setupMetaTags();
    this.userService.initSavedXplore();
    this.isLoadingUser = false;

    if(!this.router.url.includes('/label')) {
      this.analyticsService.addVisitAction();
    }

    if(!this.isBadUserAgent) {
      this.handlePwa();
    }
  }

  initializeApp() {
    if(!this.isBadUserAgent) {
      this.initLanguage();
    }
    this.filtersService.initFilters();
    this.changeTheme();
  }

  addToHomeScreen = () => {
    if(this.isBadUserAgent) {
      return;
    }

    if (this.popupModalTimeout) {
      clearTimeout(this.popupModalTimeout);
    }

    this.pwaService.show = false;
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome !== 'accepted') {
          this.pwaService.setPopupDate();
        }
        this.deferredPrompt = null;
      });
  }


  handleUserResponse(response: boolean): void {
    if (!response) {
      this.pwaService.setPopupDate();
    }
  }

  private initLanguage() {
    let selectedLanguage;
    if (localStorage.getItem('language') !== null) {
      selectedLanguage = localStorage.getItem('language');
    } else {
      selectedLanguage = this.getUsersLocale('en');
    }
    this.translation.use(selectedLanguage);
  }

  private checkArguments(): boolean {
    const url = window.location.href;
    let index = url.indexOf('args=');
    if (index > 0) {
      let args = url.substring(index + 5).split('&');
      return args.indexOf(ArgType.DOWNLOAD) >= 0;
    }
    return false;
  }

  private handlePwa() {
    this.pwaService.popup
      .subscribe(status => {
        console.log(`[PWA SERVICE]: STATUS IS ${status}`);
        if (this.pwaService.canAsk() && status) {
          if (this.checkArguments()) {
            console.log('[PWA SERVICE]: IMMEDIATE DOWNLOAD');
            this.showModal = true;
          } else {
            console.log('[PWA SERVICE]: DEFERRED DOWNLOAD');
            this.popupModalTimeout = setTimeout(() => this.showModal = true, 30000);
          }
        } else {
          console.log('[PWA SERVICE]: CAN\'T ASK, USER DENIED');
        }
      });
  }

  /**
   * Checks if the current URL includes any of the specified paths.
   * @param url The current router URL.
   * @param paths An array of path strings to check against the URL.
   * @returns A boolean indicating whether any path is included in the URL.
   */
  private isUrlInPaths(url: string, paths: string[]): boolean {
    return paths.some(path => url.includes(path));
  }


  private setupMetaTags() {
    this.metaDataService.setDefaultMetaData();
  }

  private changeTheme() {
    this.themeService.changeTheme();
  }

  private getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;

    lang = lang.toString();

    // TODO Implement a better way to handle all language
    if(lang.includes('it')) {
      return 'it';
    } else {
      return 'en';
    }
  }
}
