import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TerritoryService } from 'src/app/services/geolocation/territory.service';
import { ImageManager } from 'src/app/shared/image-manager';
import { Territory } from 'src/app/shared/object/xplorer-be-parsed-classes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xplorer-bowser-territory',
  templateUrl: './territory.component.html',
  styleUrls: ['./territory.component.css']
})
export class TerritoryComponent implements OnInit {
  protected readonly ImageManager = ImageManager;
  availableTerritories: Territory[] = environment.AVAILABLE_TERRITORY;
  currentTerritoryCode: string;

  constructor(private router: Router, private territoryService: TerritoryService) { }

  ngOnInit(): void {
    this.currentTerritoryCode = this.territoryService.getTerritoryCodeFromUrl();
  }

  goToXplorerTerritory(territory: Territory) {
    if (territory.code === this.territoryService.getTerritoryCodeFromUrl()) {
      this.router.navigate(['/explore']);
    } else {
      window.location.href = territory.url;
    }
  }
}
