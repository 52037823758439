<app-header *ngIf="showNavigation"></app-header>

<!-- <div class="container mx-auto px-[0.75rem] lg:hidden" *ngIf="showHeader">
  <xplorer-bowser-mobile-header></xplorer-bowser-mobile-header>
</div> -->

<router-outlet *ngIf="!isLoadingUser"></router-outlet>

<div *ngIf="isLoadingUser" class="height-without-bar">
  <div class="height-without-bar flex align-items-center justify-content-center">
    <div class="spinner-border custom-big-spinner" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<app-pwa-popup (userResponse)="handleUserResponse($event)" [installPWA]="addToHomeScreen" *ngIf="!isBadUserAgent"
  [showModal]="showModal"></app-pwa-popup>
<app-page-tabbar *ngIf="showNavigation" class="lg:hidden">
</app-page-tabbar>

<xplorer-bowser-location-switch-popup></xplorer-bowser-location-switch-popup>
