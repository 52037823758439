import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  CommercialDTO,
  EventDTO,
  MultiMediaDTO, TagDTO, UserAccountDTO,
  XploreItemDTO
} from "../../../shared/object/xplorer-be-parsed-classes";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import {ImageManager} from "../../../shared/image-manager";
import KeenSlider, {KeenSliderInstance} from "keen-slider";

@Component({
  selector: 'app-mobile-item-card',
  templateUrl: './mobile-item-card.component.html',
  styleUrls: ['./mobile-item-card.component.css']
})
export class MobileItemCardComponent implements AfterViewInit {

  @ViewChild("sliderHorizRef")
  sliderRef: ElementRef<HTMLElement>;

  slider: KeenSliderInstance = null;

  @Input() xploreItem: XploreItemDTO;
  @Input() distanceFromUser: string;
  @Input() isFavorite: boolean;
  @Input() canShare: boolean;

  @Output() favoriteButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() shareButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() immersiveButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchTagClickedEvent: EventEmitter<TagDTO> = new EventEmitter<TagDTO>();
  @Output() userProfileClickedEvent: EventEmitter<UserAccountDTO> = new EventEmitter<UserAccountDTO>();
  @Output() redirectToInfoPageClickedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToMapClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  destroy$: Subject<boolean> = new Subject<boolean>();
  protected readonly ImageManager = ImageManager;

  constructor( private cdr: ChangeDetectorRef ) { }

  ngAfterViewInit() {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: false,
      rubberband: false,
      slides: {
        origin: "center",
        perView: 1,
        spacing: 0,
      },
      vertical: false
    });
  }

  getImage(image: MultiMediaDTO) {
    return image?.mediaURL ? environment.IMAGES_URL  + image.mediaURL : ImageManager.getPlaceholderImage();
  }

  redirectToInfoPage() {
    this.redirectToInfoPageClickedEvent.emit();
  }

  get firstLevelTags() {
    return this.xploreItem.tags.filter(tag => tag.tagLevel == 0)
  }

  favoriteButtonClicked() {
    this.favoriteButtonClickedEvent.emit();
  }

  get hasOwner() {
    if (this.isEvent) {
      let event = this.xploreItem as EventDTO
      return event.companyDto !== null
    } else if(this.isCommercial) {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company !== null
    }
    return false;
  }

  get ownerName() {
    if (this.isCommercial) {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.company !== null ? commercial.company.name : ''
    } else if(this.isEvent) {
      let event = this.xploreItem as EventDTO
      return event.companyDto !== null ? event.companyDto.name : ''
    }
    return ''
  }

  get hasPriceRating() {
    if (this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return commercial.priceRating !== null
    } else if (this.xploreItem.itemType === 'EVENT') {
      let event = this.xploreItem as EventDTO
      return event.priceRating !== null
    }
    return false
  }

  get priceRating() {
    if(this.xploreItem.itemType === 'EVENT') {
      let event = this.xploreItem as EventDTO
      return "€".repeat(event.priceRating)
    } else if(this.xploreItem.itemType === 'COMMERCIAL') {
      let commercial = this.xploreItem as CommercialDTO
      return "€".repeat(commercial.priceRating)
    }
    return null;
  }

  share(): void {
    this.shareButtonClickedEvent.emit();
  }

  get isEvent() {
    return this.xploreItem.itemType === 'EVENT';
  }

  get isCommercial() {
    return this.xploreItem.itemType === 'COMMERCIAL';
  }

  buildTagName(tag: TagDTO) {
    let tagName = tag.code;
    tagName = tagName.replace(/_/g, ' ');
    tagName = tagName.charAt(0).toUpperCase() + tagName.slice(1);
    return tagName;
  }

  navigateToMap() {
    this.navigateToMapClickedEvent.emit();
  }

  goToImmersive() {
    this.immersiveButtonClickedEvent.emit();
  }

  goToSearchTag(tag: TagDTO) {
    this.searchTagClickedEvent.emit(tag);
  }

  goToUserProfile(userAccount: UserAccountDTO) {
    this.userProfileClickedEvent.emit(userAccount);
  }

  onImageError($event: ErrorEvent) {
    const imgElement = $event.target as HTMLImageElement;
    imgElement.src = ImageManager.getPlaceholderImage();
  }

  onImageLoad() {
    this.cdr.detectChanges();
  }
}
