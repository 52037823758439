<div
    class="absolute right-0 top-full opacity-[0] translate-y-4 pointer-events-none group-hover:opacity-100 group-hover:translate-y-0 group-hover:pointer-events-auto transform transition duration-300 ease-in-out lg:z-[100]">
    <ul class="w-56 bg-white shadow-[0_4px_16px_rgba(0,0,0,.1)] rounded-2xl py-[1rem] pt-[1rem] text-[#1D1D1B] mt-2">
        <ng-container *ngIf="!isUserLoggedIn; else loggedInMenu">
            <li class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out"
                (click)="goToRegister()">
                {{ 'register.title' | translate }}
            </li>
            <li class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out"
                (click)="goToLogin()">
                {{ 'login.login' | translate }}
            </li>
            <hr class="my-2 border-[1px] border-[#1d1d1ba6]">
            <li (click)="onLocationClick()"
                class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out">
                {{ 'dropdown_menu.change_territory' | translate }}
            </li>
        </ng-container>

        <ng-template #loggedInMenu>
            <li routerLink="/profile"
                class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out">
                {{ 'tabs.profile' | translate }}
            </li>
            <li routerLink="/favorites"
                class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out">
                {{ 'dropdown_menu.favorite_items' | translate }}
            </li>
            <li (click)="onLocationClick()"
                class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out">
                {{ 'dropdown_menu.change_territory' | translate }}
            </li>
            <hr class="my-2 border-[1px] border-[#1d1d1ba6]">
            
            <li class="px-6 py-2 hover:text-white custom-background-color-hover cursor-pointer transition duration-300 ease-in-out"
                (click)="logout()">
                {{ 'logout.title' | translate }}
            </li>
        </ng-template>
    </ul>
</div>