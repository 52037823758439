import { Injectable } from '@angular/core';
import {GeolocationService} from "@ng-web-apis/geolocation";
import {map, Observable, of, take} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {XploreItemDTO} from "../../shared/object/xplorer-be-parsed-classes";

@Injectable({
  providedIn: 'root'
})
export class UserGeolocationService {

  private currentUserGeoPosition: GeolocationPosition = null;

  constructor(private geoLocationObservable: GeolocationService) {
    this.geoLocationObservable.pipe(take(10)).subscribe((position) => {
      console.debug('UserGeolocationService: ', position);
      this.currentUserGeoPosition = position;
    });
  }

  public getDistanceXploreItemFromUserLocation(xploreItem: XploreItemDTO): string {
    return this.calculateDistance(xploreItem)?.toFixed(1);
  }

  private calculateDistance(xploreItem: XploreItemDTO): number {
    if (!xploreItem.geoPositionDTO) {
      return null;
    }

    if(!this.currentUserGeoPosition) {
      return null;
    }

    return this.calculateActualDistance(this.currentUserGeoPosition, xploreItem);
  }

  private calculateActualDistance(position: GeolocationPosition, xploreItem: XploreItemDTO) {
    const lat1 = position.coords.latitude;
    const lon1 = position.coords.longitude;
    const lat2 = xploreItem.geoPositionDTO.latitude;
    const lon2 = xploreItem.geoPositionDTO.longitude;

    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return ((R * c) / 1000); // in km
  }

  getCurrentUserLocation(): Observable<GeolocationPosition> {
    if(!this.currentUserGeoPosition) {
      return this.geoLocationObservable.pipe(take(1));
    }
    return of(this.currentUserGeoPosition);
  }
}
