import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event.service';
import { XploreItemService } from 'src/app/services/xplore-item.service';
import { XploreItemDTO } from 'src/app/shared/object/xplorer-be-parsed-classes';

@Component({
  selector: 'xplorer-bowser-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  allXploreItems: XploreItemDTO[] = [];
  loading = true;
  sectionTitle: string;
  loadingTitle = true;
  skeletons = [1, 2, 3, 4, 5];
  featuredEvent: XploreItemDTO;
  loadingFeaturedEvent = true;

  constructor(
    private xploreItemService: XploreItemService,
    private translate: TranslateService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getUserLocation();
    this.loadUpcomingPopularEvents();
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.xploreItemService.getNearbyUserXploreItems(position).subscribe(
            (data: XploreItemDTO[]) => {
              this.allXploreItems = data;
              this.sectionTitle = this.translate.instant('home.near_you');
              this.loading = false;
              this.loadingTitle = false;
            },
            (error) => {
              console.error('Errore nel recupero dei dati vicini:', error);
              this.loadGenericItems();
            }
          );
        },
        (error) => {
          console.error('Errore nel recupero della posizione:', error);
          this.loadGenericItems();
        }
      );
    } else {
      this.loadGenericItems();
    }
  }

  loadGenericItems() {
    this.xploreItemService.getXploreItems().subscribe(
      (data: XploreItemDTO[]) => {
        this.allXploreItems = data;
        this.sectionTitle = this.translate.instant('home.might_like');
        this.loading = false;
        this.loadingTitle = false;
      },
      (error) => {
        console.error('Errore nel recupero degli elementi generici:', error);
        this.loading = false;
        this.loadingTitle = false;
      }
    );
  }

  loadUpcomingPopularEvents(): void {
    this.eventService.getUpcomingPopularEvents().subscribe(
      (data: XploreItemDTO[]) => {
        if (data?.length > 0) {
          this.featuredEvent = data[0];
        }
        this.loadingFeaturedEvent = false;
      },
      (error) => {
        console.error('Errore nel recupero degli eventi in evidenza:', error);
        this.loadingFeaturedEvent = false;
      }
    );
  }

  updateSectionTitle(title: string) {
    this.sectionTitle = title;
  }

  // updateSectionTitle(newTitle: string) {
  //   this.sectionTitle = newTitle;
  //   this.loadingTitle = false;
  // }

}
