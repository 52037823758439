import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FilterService } from '../../../services/filter.service';
import { Filter } from '../../../shared/object/filter';
import { TerritoryService } from "../../../services/geolocation/territory.service";

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.css']
})
export class FilterButtonComponent implements OnInit {

  currentTerritoryCode: string;
  isActive: boolean = false;

  @Input() filter: Filter;
  @Input() loadedIndex: number;
  @Input() updateItemsFunction: () => void;

  isMobile: boolean = window.innerWidth < 768;

  constructor(
    private filterService: FilterService,
    private territoryService: TerritoryService
  ) { }

  ngOnInit(): void {
    this.currentTerritoryCode = this.territoryService.getTerritoryCodeFromUrl();
    this.isActive = this.filterService.getFilterStatus(this.filter.API_name);
  }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    this.isMobile = window.innerWidth < 768;
  }

  toggle(): void {
    if (this.filterService.checkIfNotLastActiveFilter(this.filter.API_name)) {
      this.isActive = this.filterService.changeFilterStatus(this.filter.API_name);
      this.loadedIndex = 0;
      this.updateItemsFunction();
    }
  }

  getFilterClasses(): string[] {
    const baseClasses = [
      'rounded-full',
      'flex',
      'items-center',
      'justify-center',
      'px-3',
      'py-2',
      'transition-all',
      'duration-300',
      'border-[1px]',
      'custom-border-color'
    ];

    if (this.isActive) {
      baseClasses.push('custom-background-color');
    } else {
      baseClasses.push('bg-white');
    }

    return baseClasses;
  }

  getFilterTextClasses() {
    const baseClasses = [
      'text-sm',
      'text-center',
      'font-semibold'
    ];

    if (this.isActive) {
      baseClasses.push('text-white');
    }

    return baseClasses;
  }
}
