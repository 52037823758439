export class Filter {
  name: string;
  API_name: string;
  imageName: string;
  //active: boolean;

  constructor(name, d_name, url) {
    this.name = name;
    this.API_name = d_name;
    this.imageName = url;
    //this.active = true;
  }
}
