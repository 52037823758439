import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, EMPTY, from, map, mergeMap, Observable, of} from 'rxjs';
import {environment} from "../../../environments/environment";
import {
  ApiResponse,
  LoginRequest,
  LoginResponse,
  SignUpRequest,
  UserAccountDTO
} from "../../shared/object/xplorer-be-parsed-classes";
import {TokenStorageService} from "./token-storage.service";
import {KeycloakService} from "keycloak-angular";
import {JwtUtils} from "../../shared/utils/JwtUtils";
import {UserService} from "../user/user.service";
import {KeycloakProfile} from "keycloak-js";
import {AuthToken, KeycloakUserInfo} from "../../shared/object/keycloak-classes";
import {Router} from "@angular/router";


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private keycloakService: KeycloakService,
    private userService: UserService,
    private router: Router
  ) {
  }

  googleLogin = (source: string) : Observable<void> => {
    this.tokenStorage.signOut();
    const redirectUrl = `${this.getBaseUrl()}/load-user${source ? `?source=${source}` : ''}`;
    return from(this.keycloakService.login({
      idpHint: 'google',
      redirectUri: redirectUrl
    }));
  }

  login(email: string, password: string): Observable<LoginResponse> {
    let loginRequest : LoginRequest = {
      password: password,
      email: email,
    }
    return this.http.post<LoginResponse>(environment.API_URL + '/auth/signin', loginRequest, httpOptions);
  }

  saveUserInfo = (source: Promise<KeycloakProfile> | Observable<KeycloakProfile>): Observable<UserAccountDTO> => {
    return from(source)
      .pipe(
        map(userProfile => {
          const userRoles = this.getUserRoles();
          // TODO verify this token from roles
          const userInfo: UserAccountDTO = {
            userId: null,
            keycloakId: userProfile.id,
            username: userProfile.username,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            displayedName: `${userProfile.firstName} ${userProfile.lastName}`,
            email: userProfile.email,
            roles: userRoles,
            experience: 0,
            reputation: 0,
            coins: 0,
            instagramUsername: null
          }
          return userInfo;
        }),
        mergeMap(userInfo => {
          return userInfo.email ? this.userService.upsertUserFromKeycloak(userInfo) : of(userInfo);
        })
      );
  }

  register(signUpRequest: SignUpRequest): Observable<ApiResponse> {
    signUpRequest.authProvider = 'LOCAL';
    return this.http.post<ApiResponse>(environment.AUTH_API_URL + '/signup', signUpRequest, httpOptions);
  }

  isUserLogged = (): Observable<boolean> => {
    return from(this.keycloakService.getToken())
      .pipe(
        map(userToken => {
          const defaultUserToken = this.tokenStorage.getToken();
          // TODO check if token is valid
          if(userToken) {
            this.tokenStorage.saveToken(userToken);
          }
          return (userToken !== null && userToken !== undefined) || (defaultUserToken !== null && defaultUserToken !== undefined);
        }),
        mergeMap(hasToken => from(of(this.keycloakService.isLoggedIn()))
          .pipe(
            map(isUserLogged => {
              return hasToken || isUserLogged;
            })
          ))
      );
  }

  getUserRoles = (): string[] => {
    let token = this.tokenStorage.getToken();
    if(token === null || token === undefined) {
      return [];
    } else {
      return this.tokenStorage.getRolesFromToken(token);
    }
  }

  logout = (): Observable<void> => {
    this.tokenStorage.signOut();

    let redirectUri = `${this.getBaseUrl()}/` + 'explore';
    return from(of(this.keycloakService.isLoggedIn()))
      .pipe(
        map(isUserLogged => {
          if (isUserLogged) {
            console.debug('[KEYCLOAK] Logging out');
            this.keycloakService.logout(redirectUri);
          } else {
            console.debug('[KEYCLOAK] User is not logged in');
            this.router.navigate(['/login']);
          }
        })
      );
  }

  getUser(): UserAccountDTO {
    return this.tokenStorage.getUser();
  }

  getToken(): string {
    return this.tokenStorage.getToken();
  }

  // refreshToken = (): Observable<AuthToken> => this.userService.refreshToken();

  private toKeycloakProfile = (keycloakUserInfo: KeycloakUserInfo): KeycloakProfile => {
    return {
      id: keycloakUserInfo.sub,
      firstName: keycloakUserInfo.given_name,
      lastName: keycloakUserInfo.family_name,
      email: keycloakUserInfo.email,
      enabled: true,
      emailVerified: false,
      username: keycloakUserInfo.name
    }
  }

  private getBaseUrl() {
    const parsedUrl = new URL(window.location.href);
    return parsedUrl.origin;
  }

  initKeycloakWithNewToken = (token: string, refreshToken: string) => {
    console.debug('[KEYCLOAK] Initializing Keycloak with new token');

    this.keycloakService.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT_ID,
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        token: token,
        refreshToken: refreshToken
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: []
    });

    console.debug('[KEYCLOAK] Keycloak initialized with new token');

    const keycloakAuth = this.keycloakService.getKeycloakInstance();
    const tokenService = this.tokenStorage
    keycloakAuth.onTokenExpired = function() {
      keycloakAuth.updateToken(5).then(function(refreshed) {
        if (refreshed) {
          console.debug('[KEYCLOAK] Token refreshed');
          tokenService.saveToken(keycloakAuth.token);
        } else {
          console.debug('[KEYCLOAK] Token is still valid');
        }
      });
    }
  }

  clearTokenIfExpired = () => {
    const token = this.tokenStorage.getToken();
    if (token && this.tokenStorage.isTokenExpired(token)) {
      this.tokenStorage.signOut();
    }
  }

}
